import { EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Card,
  Col,
  Descriptions,
  Divider,
  Input,
  Layout,
  Result,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Statistic,
  Table,
  TableColumnsType,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { SegmentedValue } from "antd/es/segmented";
import dayjs from "dayjs";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";

import EstimateRequestPreview from "src/components/EstimateRequestPreview";
import MediaFilesPreview from "src/components/MediaFilesPreview";
import {
  EstimateRequestsQuery,
  useEstimateRequestsLazyQuery,
  useEstimateRequestsPartnersListQuery,
  EstimateRequestSortOrderEnum,
  EstimateRequestsPartnersListQuery,
  EstimateRequestFilter,
  useEstimateRequestsCountLazyQuery,
} from "src/utils/client";
import { insuranceTypeFormatter } from "src/utils/formatter";

gql`
  query EstimateRequestsPartnersList {
    companies(filter: { isPartner: true }, limit: 30, offset: 0) {
      id
      name
      responsedEstimateCount: estimateRequestCount(filter: { responsed: true })
      unresponsedEstimateCount: estimateRequestCount(
        filter: { responsed: false }
      )
    }
  }

  query EstimateRequestsCount($filter: EstimateRequestFilter) {
    estimateRequestCount(filter: $filter)
  }

  query EstimateRequests(
    $limit: Int!
    $offset: Int!
    $filter: EstimateRequestFilter
    $sortOrder: EstimateRequestSortOrderEnum!
    $estimateRequestCountFilter: CompanyEstimateRequestFilter
  ) {
    estimateRequests(
      limit: $limit
      offset: $offset
      filter: $filter
      sortOrder: $sortOrder
    ) {
      id
      responsedAt
      createdAt
      ...EstimateRequestPreview
      memo
      company {
        id
        name
        estimateRequestCount(filter: $estimateRequestCountFilter)
        responsedEstimateCount: estimateRequestCount(
          filter: { responsed: true }
        )
        unresponsedEstimateCount: estimateRequestCount(
          filter: { responsed: false }
        )
      }
      inquiry {
        id
        client {
          name
          phone
          account {
            id
            profile {
              avatarImage {
                url
              }
            }
          }
        }
        detail {
          body
          files {
            url
          }
          areas {
            radius
            name
            zone {
              province
              district
              neighborhood
            }
          }
          insurance
        }
        vehicle {
          modelName
          modelYear
        }
      }
    }
  }
`;

const PAGE_SIZE = 10;

const { Text, Title } = Typography;
type ColumnDataType = NonNullable<
  EstimateRequestsQuery["estimateRequests"]
>[number];

const columns: TableColumnsType<ColumnDataType> = [
  {
    title: "업체 응답내용",
    dataIndex: "estimate",
    key: "estimate",
    width: 100,
    render: (_, data) => <EstimateRequestPreview data={data} />,
  },
  {
    title: "고객 문의내용",
    dataIndex: "detail",
    key: "detail",
    width: 100,
    render: (_, { id, inquiry, createdAt }) => {
      const client = inquiry?.client;
      const detail = inquiry?.detail;
      return (
        <Descriptions
          size="small"
          column={2}
          labelStyle={{ width: 100 }}
          bordered
        >
          <Descriptions.Item span={1} label="문의작성일">
            {dayjs(createdAt).format("YYYY-MM-DD HH:mm")}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="견적문의ID">
            <Link to={`/estimate-inquiries/${id}`}>{inquiry?.id}</Link>
          </Descriptions.Item>
          <Descriptions.Item span={1} label="고객정보">
            {client?.account ? (
              <Tooltip title="닥터차 유저입니다">
                <Space>
                  <Avatar
                    src={client.account?.profile.avatarImage?.url}
                    icon={<UserOutlined />}
                  />
                  <Link to={`/users/${client.account.id}`}>{client.name}</Link>
                </Space>
              </Tooltip>
            ) : (
              <Text>{client?.name}</Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="보험여부">
            <Tag color={insuranceTypeFormatter(detail?.insurance).color}>
              {insuranceTypeFormatter(detail?.insurance).text}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item span={2} label="요청지역">
            {detail?.areas.map((area, i) => (
              <Tag icon={<EnvironmentOutlined />} key={i}>
                {area.zone?.province} {area.zone?.district}{" "}
                {area.zone?.neighborhood} ({area.radius / 1000}km 반경)
              </Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="요청내용">
            <Text type="secondary">{detail?.body}</Text>
          </Descriptions.Item>
          <Descriptions.Item span={2} label="차량정보">
            {inquiry?.vehicle?.modelName} {inquiry?.vehicle?.modelYear}년식
          </Descriptions.Item>
          <Descriptions.Item span={2} label="첨부자료">
            <MediaFilesPreview files={detail?.files} height={120} />
          </Descriptions.Item>
        </Descriptions>
      );
    },
  },
];

const EstimateRequests: React.FC = () => {
  const [responsed, setResponsed] = React.useState("all" as SegmentedValue);
  const [memo, setMemo] = React.useState("all" as SegmentedValue);
  const [unpublished, setUnpublished] = React.useState("all" as SegmentedValue);
  const [searchMemo, setSearchMemo] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const { data: companyList, loading: companyListLoading } =
    useEstimateRequestsPartnersListQuery();
  const [estimateRequestsQuery, { data, loading, error }] =
    useEstimateRequestsLazyQuery();
  const [estimateRequestsCountQuery, { data: estimateRequestsCount }] =
    useEstimateRequestsCountLazyQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCompany = searchParams.get("companyId");
  const selectedCompanyName = selectedCompany
    ? companyList?.companies.filter(
        (company) => company.id === selectedCompany
      )[0].name
    : "전체";

  React.useEffect(() => {
    const filter: EstimateRequestFilter = {
      companyId: selectedCompany ? selectedCompany : undefined,
      hasResponsed: responsed === "all" ? undefined : responsed === "yes",
      hasUnpublishedEstimate:
        unpublished === "all" ? undefined : unpublished === "yes",
      hasMemo: memo === "all" ? undefined : memo === "yes",
      memo: searchMemo === "" ? undefined : searchMemo,
    };

    estimateRequestsCountQuery({
      variables: {
        filter,
      },
    });

    estimateRequestsQuery({
      variables: {
        estimateRequestCountFilter: {
          responsed: responsed === "all" ? undefined : responsed === "yes",
        },
        filter,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        sortOrder: EstimateRequestSortOrderEnum.CreatedAtDesc,
      },
    });
  }, [
    responsed,
    memo,
    unpublished,
    searchMemo,
    page,
    estimateRequestsQuery,
    pageSize,
    selectedCompany,
    estimateRequestsCountQuery,
  ]);

  const hasSelectedCompanyData = selectedCompany && data?.estimateRequests[0];

  return (
    <Layout className="content-container">
      <Title level={2}>{selectedCompanyName} 견적 요청 목록</Title>
      <Card size={"small"}>
        <Row>
          <Col span={4}>
            <Statistic
              title={"전체 견적요청"}
              value={estimateRequestsCount?.estimateRequestCount}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title={"응답한 견적요청"}
              value={
                hasSelectedCompanyData
                  ? data?.estimateRequests[0].company?.responsedEstimateCount
                  : "-"
              }
            />
          </Col>
          <Col span={4}>
            <Statistic
              title={"미응답 견적요청"}
              value={
                hasSelectedCompanyData
                  ? data?.estimateRequests[0].company?.unresponsedEstimateCount
                  : "-"
              }
            />
          </Col>
        </Row>
        <hr />
        <Space>
          {companyList && (
            <Select
              value={selectedCompany || ALL_COMPANIES}
              loading={companyListLoading}
              onChange={(value) => {
                if (value === ALL_COMPANIES) {
                  searchParams.delete("companyId");
                  setSearchParams(searchParams);
                } else {
                  setSearchParams({ companyId: value });
                }
              }}
              placeholder="업체 선택"
              style={{ width: 360 }}
              options={getCompanyList(companyList)}
            />
          )}
          <Divider type="vertical" />
          <Space direction="vertical">
            <Space>
              <Text>견적서 응답 여부: </Text>
              <Segmented
                value={responsed}
                onChange={(value) => {
                  setResponsed(value);
                }}
                options={[
                  { value: "all", label: "전체" },
                  { value: "yes", label: "응답O" },
                  { value: "no", label: "응답X" },
                ]}
                onResize={undefined}
                onResizeCapture={undefined}
              />
            </Space>
            <Space>
              <Text>발행 대기 상태: </Text>
              <Segmented
                value={unpublished}
                onChange={(value) => {
                  setUnpublished(value);
                }}
                options={[
                  { value: "all", label: "전체" },
                  { value: "yes", label: "대기O" },
                  { value: "no", label: "대기X" },
                ]}
                onResize={undefined}
                onResizeCapture={undefined}
              />
            </Space>
          </Space>
          <Divider type="vertical" />
          <Space direction="vertical">
            <Space>
              <Text>메모: </Text>
              <Segmented
                value={memo}
                onChange={(value) => {
                  setMemo(value);
                }}
                options={[
                  { value: "all", label: "전체" },
                  { value: "yes", label: "O" },
                  { value: "no", label: "X" },
                ]}
                onResize={undefined}
                onResizeCapture={undefined}
              />
            </Space>
            <Space>
              <Text>메모 검색: </Text>
              <Input.TextArea
                value={searchMemo}
                onChange={(e) => {
                  setSearchMemo(e.target.value);
                }}
                allowClear
                placeholder="메모를 검색하세요."
                autoSize={{ minRows: 1, maxRows: 8 }}
              />
            </Space>
          </Space>
        </Space>
      </Card>
      {error && (
        <Result
          status={"error"}
          title="정보 로드에 문제가 발생했습니다"
          subTitle={error.message}
        />
      )}

      <Spin tip="Loading" spinning={loading}>
        {data?.estimateRequests && (
          <Table
            bordered
            size={"small"}
            columns={columns}
            dataSource={data?.estimateRequests}
            loading={loading}
            sticky
            pagination={{
              position: ["topLeft", "bottomLeft"],
              current: page,
              pageSize,
              total: estimateRequestsCount?.estimateRequestCount,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        )}
      </Spin>
    </Layout>
  );
};

export default EstimateRequests;

const ALL_COMPANIES = "all";

const getCompanyList = (companyList: EstimateRequestsPartnersListQuery) => {
  const allCompanies = {
    value: ALL_COMPANIES,
    label: "전체",
  };
  return [
    allCompanies,
    ...(companyList?.companies || []).map((company) => ({
      value: company.id,
      label:
        company.name +
        " (응답완료: " +
        company.responsedEstimateCount +
        ", 미응답: " +
        company.unresponsedEstimateCount +
        ")",
    })),
  ];
};
