import { Layout, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const Home: React.FC = () => {
  return (
    <Layout className="content-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Title type="secondary" level={3}>
          좌측 메뉴를 선택해 작업을 시작하세요
        </Title>
      </div>
    </Layout>
  );
};

export default Home;
