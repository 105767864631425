import { EditOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Divider, Layout, Result, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import MediaFilesPreview from "src/components/MediaFilesPreview";
import { useNoticeQuery } from "src/utils/client";

gql`
  query Notice($noticeId: ID!) {
    notice(noticeId: $noticeId) {
      id
      title
      body
      files {
        url
      }
      createdAt
    }
  }
`;

const { Title, Paragraph, Text } = Typography;

const Notice = () => {
  const { id } = useParams();
  const { data, loading, error } = useNoticeQuery({
    skip: !id,
    variables: { noticeId: id || "" },
  });
  const navigate = useNavigate();

  return (
    <Layout className="content-container">
      <Spin spinning={loading}>
        {error && (
          <Result
            status="error"
            title="공지사항을 불러오는데 실패했습니다."
            subTitle={error.message}
          />
        )}
        {data?.notice && (
          <>
            <Title level={2}>{data.notice.title}</Title>
            <Paragraph>{data.notice.body}</Paragraph>
            <MediaFilesPreview files={data.notice.files} height={200} />
            <Divider />
            <Paragraph>
              공지사항ID:{" "}
              <Text copyable code>
                {data.notice.id}
              </Text>
            </Paragraph>
            <Paragraph>
              생성일: {dayjs(data.notice.createdAt).format("YYYY.MM.DD")}
            </Paragraph>
            <Space>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  if (id) {
                    navigate(`/notices/${id}/edit`);
                  }
                }}
              >
                수정
              </Button>
              {/* <Button
                danger
                icon={<DeleteOutlined />}
              >
                삭제
              </Button> */}
            </Space>
          </>
        )}
      </Spin>
    </Layout>
  );
};

export default Notice;
