import { CheckOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Input, Space, Typography } from "antd";
import React, { useEffect } from "react";

import { useEditEstimateRequestMemoMutation } from "src/utils/client";
const { Text } = Typography;
gql`
  mutation EditEstimateRequestMemo($requestId: ID!, $memo: String!) {
    editEstimateRequestMemo(requestId: $requestId, memo: $memo) {
      ... on EditEstimateRequestMemoFail {
        error {
          message
        }
      }
      ... on EditEstimateRequestMemoSuccess {
        request {
          id
          memo
        }
      }
    }
  }
`;
type EstimateRequestMemoProps = {
  initialMemo: string;
  requestId: string;
};

const EstimateRequestMemo: React.FC<EstimateRequestMemoProps> = ({
  initialMemo,
  requestId,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const [memo, setMemo] = React.useState(initialMemo);

  useEffect(() => {
    setMemo(initialMemo);
  }, [initialMemo]);

  const [editMemo, { loading: editMemoLoading }] =
    useEditEstimateRequestMemoMutation();

  const onSubmitMemo = async () => {
    const { data: editMemoData } = await editMemo({
      variables: {
        memo,
        requestId,
      },
    });
    if (
      editMemoData?.editEstimateRequestMemo.__typename ===
      "EditEstimateRequestMemoFail"
    ) {
      alert(editMemoData.editEstimateRequestMemo?.error);
    }
  };

  return (
    <Space onClick={() => setIsActive(true)}>
      <Input.TextArea
        disabled={!isActive}
        onFocus={() => setIsActive(true)}
        value={memo}
        onChange={(e) => {
          setMemo(e.target.value);
        }}
        allowClear
        autoSize={{ minRows: 1, maxRows: 8 }}
      />
      <Button
        disabled={initialMemo === memo}
        type="primary"
        icon={<CheckOutlined />}
        onClick={onSubmitMemo}
        loading={editMemoLoading}
      >
        저장
      </Button>
    </Space>
  );
};
export default EstimateRequestMemo;
