import { InMemoryCache, gql, makeVar } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

import { TypedTypePolicies } from "./apollo-helpers";
import generatedPossibleTypes from "./fragment-matcher";

export const loggedInVar = makeVar<boolean>(!!localStorage.getItem("token"));

export const typeDefs = gql`
  extend type Estimatation {
    localDescription: String!
  }
`;

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      pendingCounselConnection: relayStylePagination(),
      finishedCounselConnection: relayStylePagination(),
    },
  },
  Counsel: {
    fields: {
      messageConnection: relayStylePagination(),
    },
  },
  Estimatation: {
    fields: {
      localDescription: {
        read(_, data) {
          const id = data.readField("id") as string | undefined;
          if (!id) return undefined;
          return localStorage.getItem(id);
        },
      },
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes: generatedPossibleTypes.possibleTypes,
  typePolicies,
});

export default cache;
export type Cache = typeof cache;

export const generateCache = () => {
  return new InMemoryCache({
    possibleTypes: generatedPossibleTypes.possibleTypes,
    typePolicies,
  });
};
