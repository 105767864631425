import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Button,
  Descriptions,
  Divider,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

import EstimateRequestMemo from "./EstimateRequestMemo";
import MediaFilesPreview from "./MediaFilesPreview";

import { EstimateRequestPreviewFragment } from "src/utils/client";
import env from "src/utils/env";
import {
  accountRoleColorFormatter,
  accountRoleFormatter,
} from "src/utils/formatter";

gql`
  fragment EstimateRequestPreview on EstimateRequest {
    id
    company {
      id
      name
    }
    memo
    estimate {
      id
      price
      description
      localDescription @client
      estimatedRepairDays
      files {
        url
      }
      hasPublished
      publisher {
        id
        profile {
          avatarImage {
            url
          }
          nickname
        }
      }
      createdAt
    }
    responsedAt
  }
`;

const { Text, Link: AntdLink } = Typography;

type EstimateRequestPreviewProps = {
  data: EstimateRequestPreviewFragment;
};

const EstimateRequestPreview = ({ data }: EstimateRequestPreviewProps) => {
  const estimate = data?.estimate;
  const company = data?.company;

  return (
    <Descriptions
      size="small"
      column={2}
      labelStyle={{ width: 100 }}
      style={{ minWidth: 280 }}
      bordered
    >
      <Descriptions.Item span={2} label="견적 요청 메모">
        <EstimateRequestMemo
          initialMemo={data?.memo || ""}
          requestId={data?.id}
        />
      </Descriptions.Item>
      <Descriptions.Item span={2} label="견적서ID">
        {data?.estimate?.id ? (
          <AntdLink href={env.WEB_URL + `/estimates/${estimate?.id}`} copyable>
            <Tooltip title="닥터차 웹에서 견적서 확인">{estimate?.id}</Tooltip>
          </AntdLink>
        ) : (
          <Text type="secondary">없음</Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="업체">
        <Tooltip title="이 업체의 견적요청 목록 확인">
          <Link to={`/estimate-requests?companyId=${company?.id}`}>
            {company?.name}
          </Link>
        </Tooltip>
      </Descriptions.Item>
      <Descriptions.Item span={2} label="응답일시">
        <Link to={`/estimate-inquiries/${data.id}`}>
          {data.responsedAt ? (
            data.estimate?.createdAt ? (
              <Tooltip title="클릭하여 견적요청 상세 확인">
                <Text type="success" underline>
                  {dayjs(data.responsedAt).format("YYYY-MM-DD HH:mm")}
                </Text>
              </Tooltip>
            ) : (
              <Tooltip title="응답 없이 완료로 전환">
                <Text type="warning" underline>
                  {dayjs(data.responsedAt).format("YYYY-MM-DD HH:mm")}
                </Text>
              </Tooltip>
            )
          ) : (
            <Tooltip title="클릭하여 견적요청 상세 확인">
              <Text type="danger" underline>
                없음
              </Text>
            </Tooltip>
          )}
        </Link>
      </Descriptions.Item>
      {estimate && (
        <>
          <Descriptions.Item span={2} label="발행상태">
            {estimate.hasPublished ? (
              <Space>
                <Text type="success">
                  <CheckCircleOutlined /> 발행됨
                </Text>
                {estimate.publisher !== null ? (
                  <Tooltip title="견적 발행자">
                    <Space>
                      <Avatar
                        size={"small"}
                        src={estimate.publisher?.profile.avatarImage?.url}
                        icon={<UserOutlined />}
                      />
                      <Text>{estimate.publisher?.profile?.nickname}</Text>
                    </Space>
                  </Tooltip>
                ) : (
                  <Tag>자동발행</Tag>
                )}
              </Space>
            ) : (
              <Text type="danger">
                <InfoCircleOutlined /> 발행대기
              </Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="견적액">
            <Text>{(estimate.price || 0).toLocaleString()}원</Text>
          </Descriptions.Item>
          <Descriptions.Item span={1} label="수리기간">
            <Text>{estimate.estimatedRepairDays}일</Text>
          </Descriptions.Item>
          <Descriptions.Item span={2} label="응답내용">
            {estimate.localDescription ? (
              <Space>
                <Text type="secondary">{estimate.localDescription}</Text>
                <Link to={`/estimate-inquiries/${data?.id}`}>
                  <Button
                    type="text"
                    icon={<ExclamationCircleOutlined />}
                    size="small"
                  >
                    수정중
                  </Button>
                </Link>
              </Space>
            ) : (
              <Text type="secondary">{estimate.description}</Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="첨부자료">
            <MediaFilesPreview files={estimate.files} />
          </Descriptions.Item>
        </>
      )}
    </Descriptions>
  );
};

export default EstimateRequestPreview;
