import { gql, useReactiveVar } from "@apollo/client";
import { notification } from "antd";
import React from "react";

import { loggedInVar } from "src/utils/cache";
import { useMeQuery } from "src/utils/client";

export type Me = {
  id: string;
  avatar?:
    | {
        __typename?: "Image" | undefined;
        url: string;
      }
    | null
    | undefined;
  email?: string | null | undefined;
  nickname: string;
};

gql`
  query me($loggedIn: Boolean!) {
    me @include(if: $loggedIn) {
      id
      avatar {
        url
      }
      email
      nickname
      role
    }
  }
`;

export const useMe = () => {
  const loggedIn = useReactiveVar(loggedInVar);
  const { data, loading, refetch } = useMeQuery({
    variables: {
      loggedIn,
    },
    onError: (error) => {
      notification.error({
        message: "에러",
        description: error.message,
      });
    },
  });

  React.useEffect(() => {
    if (loggedIn) {
      refetch({ loggedIn });
    }
  }, [loggedIn, refetch]);

  const logout = () => {
    localStorage.removeItem("token");
    loggedInVar(false);
  };

  return {
    me: data?.me,
    logout,
    loading,
  };
};
