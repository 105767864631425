import { gql } from "@apollo/client";
import {
  Card,
  Layout,
  Pagination,
  Result,
  Segmented,
  Space,
  Spin,
  Typography,
  DatePicker,
  Table,
} from "antd";
import { SegmentedValue } from "antd/es/segmented";
import React from "react";

import { useSearchableCompanyReviewsLazyQuery } from "src/utils/client";

gql`
  query searchableCompanyReviews(
    $filter: SearchableCompanyReviewFilter!
    $sortOrder: SearchableCompanyReviewSortOrderEnum
    $offset: Int!
    $limit: Int!
  ) {
    searchableCompanyReviewCount(filter: $filter)
    searchableCompanyReviews(
      filter: $filter
      sortOrder: $sortOrder
      offset: $offset
      limit: $limit
    ) {
      id
      content
      author {
        id
        profile {
          nickname
        }
      }
      searchableCompany {
        id
        name
      }
      autogenerated
    }
  }
`;

export type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

const PAGE_SIZE = 10;

const { Title, Text } = Typography;

const Reviews = () => {
  const [autoGenerated, setAutoGenerated] =
    React.useState<SegmentedValue>("false");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const [searchableCompanyReviewQuery, { data, loading, error }] =
    useSearchableCompanyReviewsLazyQuery();

  React.useEffect(() => {
    searchableCompanyReviewQuery({
      variables: {
        offset: (page - 1) * pageSize,
        limit: pageSize,
        filter: {
          autogenerated: autoGenerated === "true",
        },
      },
    });
  }, [page, pageSize, searchableCompanyReviewQuery, autoGenerated]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 240,
    },
    {
      title: "리뷰 내용",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "업체명",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "작성자 닉네임",
      dataIndex: "writer",
      key: "writer",
      width: 130,
    },
  ];

  const reviews = React.useMemo(() => {
    if (!data?.searchableCompanyReviews) {
      return [];
    }

    return data.searchableCompanyReviews.map((review) => ({
      id: review.id,
      content: review.content,
      company: review.searchableCompany
        ? review.searchableCompany.name
        : "(알수 없음)",
      writer: review.author ? review.author.profile.nickname : "(알수 없음)",
      autoGenerated: review.autogenerated ? "자동생성됨" : "유저가 작성함",
    }));
  }, [data]);

  return (
    <Layout className="content-container">
      <Title level={2}>정비소 리뷰 조회</Title>
      <Card size="small">
        <Space>
          <Text>자동 생성 여부: </Text>
          <Segmented
            value={autoGenerated}
            onChange={(value) => {
              setAutoGenerated(value);
            }}
            options={[
              {
                value: "false",
                label: "유저가 작성함",
              },
              {
                value: "true",
                label: "자동생성됨",
              },
            ]}
            onResize={undefined}
            onResizeCapture={undefined}
          />
        </Space>
      </Card>
      {error && (
        <Result
          status={"error"}
          title="정보 로드에 문제가 발생했습니다"
          subTitle={error.message}
        />
      )}
      <Spin spinning={loading}>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <Pagination
            current={page}
            pageSize={pageSize}
            total={data?.searchableCompanyReviewCount}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />
          <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={reviews}
            pagination={false}
            size="small"
            rowKey="id"
          />
          {!loading && (
            <Pagination
              current={page}
              pageSize={pageSize}
              total={data?.searchableCompanyReviewCount}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }}
            />
          )}
        </div>
      </Spin>
    </Layout>
  );
};

export default Reviews;
