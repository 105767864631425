import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Button,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Popover,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { RcFile } from "antd/es/upload";
import { FormInstance } from "antd/lib/form/Form";
import { UploadFile } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React from "react";

import MediaFilesPreview from "src/components/MediaFilesPreview";
import { useFileUpload } from "src/hooks/useFileUpload";
import cache from "src/utils/cache";
import {
  useEditEstimateMutation,
  useEstimateQuery,
  usePublishEstimateMutation,
  FileInput,
  FileType,
} from "src/utils/client";
import env from "src/utils/env";

const { Text, Link: AntdLink, Paragraph } = Typography;

gql`
  query Estimate($estimateId: ID!) {
    estimate(estimateId: $estimateId) {
      id
      price
      description
      localDescription @client
      estimatedRepairDays
      files {
        url
      }
      hasPublished
      publisher {
        id
        profile {
          avatarImage {
            url
          }
          nickname
        }
      }
      createdAt
    }
  }

  mutation editEstimate($data: EditEstimateData!, $estimateId: ID!) {
    editEstimate(data: $data, estimateId: $estimateId) {
      ... on EditEstimateFail {
        error {
          message
        }
      }
      ... on EditEstimateSuccess {
        estimate {
          id
          description
          estimatedRepairDays
          files {
            ... on Image {
              url
            }
            ... on Video {
              url
            }
          }
          price
        }
      }
    }
  }

  mutation publishEstimate($estimateId: ID!) {
    publishEstimate(estimateId: $estimateId) {
      ... on PublishEstimateFail {
        error {
          message
        }
      }
      ... on PublishEstimateSuccess {
        estimate {
          id
          hasPublished
          publisher {
            id
            profile {
              avatarImage {
                url
              }
              nickname
            }
          }
        }
      }
    }
  }
`;

type EstimateProps = {
  estimateId: string;
};

type UploadFiles = {
  files: FileInput[];
};

const Estimate: React.FC<EstimateProps> = ({ estimateId }) => {
  const { data, loading } = useEstimateQuery({
    variables: {
      estimateId,
    },
  });
  const [editEstimate, { loading: editLoading }] = useEditEstimateMutation();
  const [publishEstimate, { loading: publishLoading }] =
    usePublishEstimateMutation();
  const [description, setDescription] = React.useState("");
  const [estimatedRepairDays, setEstimatedRepairDays] = React.useState<
    number | undefined
  >();
  const [price, setPrice] = React.useState<number | undefined>();

  const [form] = Form.useForm<UploadFiles>();

  const { upload } = useFileUpload();

  const handleUpload = ({ file, onSuccess, onError }: UploadRequestOption) => {
    if (file instanceof File) {
      upload(file)
        .then((res) => {
          onSuccess?.(res, new XMLHttpRequest());
        })
        .catch(onError);
    }
  };

  React.useEffect(() => {
    if (data?.estimate) {
      setDescription(
        data?.estimate?.localDescription || data?.estimate?.description || ""
      );
      setEstimatedRepairDays(data?.estimate?.estimatedRepairDays);
      setPrice(data?.estimate?.price);
      form.setFieldValue("files", data?.estimate?.files);
    }
  }, [data]);

  if (!data) return null;

  const estimate = data?.estimate;

  const onSubmit = async () => {
    const formattedFiles = form.getFieldValue("files").map((file: any) => {
      return {
        type: FileType.Image,
        url: file.url ? file.url : file.response.url,
      };
    });

    const { data: editEstimateData } = await editEstimate({
      variables: {
        data: {
          description,
          estimatedRepairDays,
          price,
          files: formattedFiles,
        },
        estimateId,
      },
    });
    if (editEstimateData?.editEstimate.__typename === "EditEstimateFail") {
      alert(editEstimateData.editEstimate?.error);
    }

    const { data: publishEstimateData } = await publishEstimate({
      variables: {
        estimateId,
      },
    });
    if (
      publishEstimateData?.publishEstimate?.__typename === "PublishEstimateFail"
    ) {
      alert(publishEstimateData?.publishEstimate?.error);
    } else if (
      publishEstimateData?.publishEstimate?.__typename ===
      "PublishEstimateSuccess"
    ) {
      localStorage.removeItem(estimateId);
      notification.success({
        message: "견적서가 발행되었습니다.",
      });
    }
  };

  return (
    <Spin spinning={editLoading || publishLoading}>
      <Descriptions
        bordered
        size="small"
        column={1}
        labelStyle={{ width: 100 }}
      >
        <Descriptions.Item label="견적서ID">
          <Tooltip title="클릭하여 닥터차 웹상에서 견적서 확인">
            <AntdLink href={env.WEB_URL + "/estimates/" + estimate?.id}>
              {estimate?.id}
            </AntdLink>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="발행상태">
          <Space split={<Divider type="vertical" />}>
            {estimate?.hasPublished ? (
              <Space split={<Divider type="vertical" />}>
                <Text type="success">
                  <CheckCircleOutlined /> 발행됨
                </Text>
                {estimate?.publisher === null ? (
                  <Tag>자동발행</Tag>
                ) : (
                  <Tooltip title="견적 발행자">
                    <Space>
                      <Avatar
                        size={"small"}
                        src={estimate?.publisher?.profile.avatarImage?.url}
                        icon={<UserOutlined />}
                      />
                      <Text>{estimate?.publisher?.profile?.nickname}</Text>
                    </Space>
                  </Tooltip>
                )}
              </Space>
            ) : (
              <>
                <Text type="danger">
                  <InfoCircleOutlined /> 발행대기
                </Text>
                <Button
                  type="primary"
                  icon={<SendOutlined />}
                  onClick={onSubmit}
                >
                  견적서 발행하기
                </Button>
              </>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="수리기간">
          <Space>
            {estimate?.hasPublished ? (
              <Text>{estimate?.estimatedRepairDays}</Text>
            ) : (
              <Input
                value={estimatedRepairDays}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  setEstimatedRepairDays(Number(e.target.value));
                }}
              />
            )}
            <Text>일</Text>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="견적액">
          <Space>
            {estimate?.hasPublished ? (
              <Text>{(estimate?.price || 0).toLocaleString()}</Text>
            ) : (
              <>
                {price && (
                  <InputNumber<number>
                    style={{ width: 180 }}
                    defaultValue={price}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    onChange={(value) => {
                      if (!value) return;
                      if (typeof value === "string") return;
                      setPrice(Number(value));
                    }}
                  />
                )}
              </>
            )}
            <Text>원</Text>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={
                <Paragraph>
                  <ul>
                    <li>발행된 견적서는 수정할 수 없습니다.</li>
                    <li>수정하면 로컬에서 바로 임시저장됩니다.</li>
                    <li>임시저장된 내용은 다른 사람은 볼 수 없습니다.</li>
                    <li>
                      placeholder로 보이는 내용은 업체가 작성했던 원본
                      내용입니다.
                    </li>
                    <li>
                      모든 내용을 지울 경우 최초 업체의 견적 내용으로 자동
                      설정합니다 (초기화)
                    </li>
                  </ul>
                </Paragraph>
              }
            >
              <Text>
                응답내용{" "}
                <Text type="secondary">
                  <InfoCircleOutlined />
                </Text>
              </Text>
            </Popover>
          }
        >
          {estimate?.hasPublished ? (
            <Text>{estimate?.description}</Text>
          ) : (
            <Spin spinning={editLoading || publishLoading}>
              <Input.TextArea
                value={description}
                onChange={(e) => {
                  if (estimate?.id) {
                    setDescription(e.target.value);
                    localStorage.setItem(estimate?.id, e.target.value);
                    cache.writeFragment({
                      id: `Estimatation:${estimate?.id}`,
                      fragment: gql`
                        fragment EstimateDescription on Estimatation {
                          localDescription
                        }
                      `,
                      data: {
                        localDescription: e.target.value,
                      },
                    });
                  }
                }}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </Spin>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="첨부자료">
          {estimate?.hasPublished ? (
            <MediaFilesPreview files={estimate?.files} height={300} />
          ) : (
            <Form form={form} labelCol={{ span: 1 }} disabled={loading}>
              <Form.Item
                name="files"
                valuePropName="fileList"
                getValueFromEvent={(e: any) => {
                  if (e.file.status === "removed") {
                    return removeSelectedFile(e, form);
                  }
                  console.log(e.file.status);

                  return Array.isArray(e) ? e : e?.fileList || [];
                }}
              >
                <Upload
                  multiple
                  customRequest={handleUpload}
                  accept="image/*"
                  listType="picture-card"
                  onPreview={onPreview}
                >
                  + 추가
                </Upload>
              </Form.Item>
            </Form>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  );
};
export default Estimate;

const removeSelectedFile = (e: any, form: FormInstance<UploadFiles>) => {
  const images = form.getFieldValue("files");

  const fileIdentifier = e.file.__typename === "Image" ? "url" : "uid";
  const filtered = images.filter(
    (image: any) => image[fileIdentifier] !== e.file[fileIdentifier]
  );

  form.setFieldValue("files", filtered);
  return filtered;
};

const onPreview = async (file: UploadFile) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};
