import { gql } from "@apollo/client";
import {
  Descriptions,
  Divider,
  Typography,
  Table,
  Space,
  Button,
  TableColumnsType,
  Layout,
  Result,
  Radio,
  message,
  Popconfirm,
  Tooltip,
} from "antd";
import React from "react";
import { useParams } from "react-router-dom";

import Loading from "src/components/Loading";
import MediaFilesPreview from "src/components/MediaFilesPreview";
import {
  Image as ImageType,
  MakeAdminAccountError,
  MakeBizAccountError,
  MakeCounselorAccountError,
  MakeUserAccountError,
  RoleEnum,
  useMakeAdminAccountMutation,
  useMakeBizAccountMutation,
  useMakeCounselorAccountMutation,
  useMakeUserAccountMutation,
  UserDocument,
  UserQuery,
  useUserLazyQuery,
  Video as VideoType,
} from "src/utils/client";
import env from "src/utils/env";
import { openCounselWindow } from "src/utils/external";
import { accountRoleFormatter } from "src/utils/formatter";

gql`
  query User($userId: ID!) {
    account(accountId: $userId) {
      id
    }
    user(userId: $userId) {
      id
      age
      gender
      avatar {
        url
      }
      name
      nickname
      role
      email
      phone
      vehicles {
        id
        plateNumber
        vin
        fuelType {
          base
          hybrid
        }
        registeredYear
        mileage
        characteristics {
          id
          name
        }
        brand {
          id
          name
          domestic
        }
        fullModelName
      }
      characteristics {
        id
        name
      }
      notification {
        counsel
        marketing
        post
      }
      commentCount
      postCount
      lastDevice {
        appVersion
        brand
        model
        os
        osVersion
      }
      activeCounsels {
        id
        status
        memo
        members {
          id
          nickname
          role
        }
        unreadCount
      }
      notificationConnection(first: 10) {
        edges {
          node {
            id
            viewed
            createdAt
          }
        }
      }
      unreadNotificationCount
      postConnection(first: 10) {
        edges {
          node {
            id
            title
            body
            files {
              url
            }
          }
        }
      }
    }
  }
`;

gql`
  mutation makeBizAccount($accountId: ID!) {
    makeBizAccount(accountId: $accountId) {
      ... on MakeBizAccountSuccess {
        account {
          id
        }
      }
      ... on MakeBizAccountFail {
        error {
          message
        }
      }
    }
  }

  mutation makeUserAccount($accountId: ID!) {
    makeUserAccount(accountId: $accountId) {
      ... on MakeUserAccountSuccess {
        account {
          id
        }
      }
      ... on MakeUserAccountFail {
        error {
          message
        }
      }
    }
  }

  mutation makeAdminAccount($accountId: ID!) {
    makeAdminAccount(accountId: $accountId) {
      ... on MakeAdminAccountSuccess {
        account {
          id
        }
      }
      ... on MakeAdminAccountFail {
        error {
          message
        }
      }
    }
  }

  mutation makeCounselorAccount($accountId: ID!) {
    makeCounselorAccount(accountId: $accountId) {
      ... on MakeCounselorAccountSuccess {
        account {
          id
        }
      }
      ... on MakeCounselorAccountFail {
        error {
          message
        }
      }
    }
  }
`;

const { Title, Text, Link } = Typography;

type Member = {
  id: string;
  nickname: string;
  role: RoleEnum;
};

const activeCounselColumns: TableColumnsType<
  UserQuery["user"]["activeCounsels"][number]
> = [
  {
    title: "상담ID",
    dataIndex: "id",
    key: "id",
    render: (id) => {
      return (
        <Button
          type="link"
          onClick={() => {
            openCounselWindow(id);
          }}
        >
          {id}
        </Button>
      );
    },
  },
  {
    title: "상태",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "메모",
    dataIndex: "memo",
    key: "memo",
  },
  {
    title: "멤버",
    dataIndex: "members",
    key: "members",
    render: (members: Member[]) => {
      return members.map(
        (member: { id: string; role: RoleEnum; nickname: string }) => {
          return (
            <Space key={member.id}>
              <Text>{member.nickname}</Text>
              <Text code>{member.role}</Text>
            </Space>
          );
        }
      );
    },
  },
];

const postColumns: TableColumnsType<
  UserQuery["user"]["postConnection"]["edges"][number]["node"]
> = [
  {
    title: "게시글ID",
    dataIndex: "id",
    key: "id",
    width: 240,
    render: (id) => {
      return (
        <Tooltip title="닥터차 웹에서 확인">
          <Link
            onClick={() => {
              window.open(`${env.WEB_URL}/post/${id}`);
            }}
          >
            {id}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    title: "제목",
    dataIndex: "title",
    key: "title",
    width: 300,
  },
  {
    title: "내용",
    dataIndex: "body",
    key: "body",
  },
  {
    title: "파일",
    dataIndex: "files",
    key: "files",
    ellipsis: true,
    render: (files: Array<ImageType | VideoType>) => {
      return <MediaFilesPreview files={files} height={240} />;
    },
  },
];

const vehicleColumns: TableColumnsType<UserQuery["user"]["vehicles"][number]> =
  [
    {
      title: "차량ID",
      dataIndex: "id",
      key: "id",
      render: (id) => {
        return (
          <Button type="link" href={`/vehicles?vehicleId=${id}`}>
            {id}
          </Button>
        );
      },
    },
    {
      title: "차종명",
      dataIndex: "fullModelName",
      key: "fullModelName",
    },
    {
      title: "제조일",
      dataIndex: "registeredYear",
      key: "registeredYear",
      render: (registeredYear) => {
        return registeredYear ? registeredYear : "-";
      },
    },
    {
      title: "주행거리(km)",
      dataIndex: "mileage",
      key: "mileage",
      render: (mileage) => {
        return mileage ? mileage : "-";
      },
    },
    {
      title: "차량번호",
      dataIndex: "plateNumber",
      key: "plateNumber",
      render: (plateNumber) => {
        return plateNumber ? plateNumber : "-";
      },
    },
    {
      title: "차대번호",
      dataIndex: "vin",
      key: "vin",
      render: (vin) => {
        return vin ? vin : "-";
      },
    },
  ];

const UserPage: React.FC = () => {
  const [userQuery, { data, loading, error }] = useUserLazyQuery({});
  const [accountRole, setAccountRole] = React.useState<string | undefined>(
    undefined
  );
  const [makeBizAccount] = useMakeBizAccountMutation();
  const [makeUserAccount] = useMakeUserAccountMutation();
  const [makeAdminAccount] = useMakeAdminAccountMutation();
  const [makeCounselorAccount] = useMakeCounselorAccountMutation();
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      userQuery({
        variables: {
          userId: id,
        },
      });
    }
  }, [id, userQuery]);

  React.useEffect(() => {
    if (data) {
      setAccountRole(data.account?.__typename);
    }
  }, [data]);

  const changeRole = () => {
    if (!id) return;
    if (accountRole === "AdminAccount") {
      makeAdminAccount({
        variables: {
          accountId: id,
        },
        onCompleted: (data) => {
          if (data.makeAdminAccount.__typename === "MakeAdminAccountFail") {
            const error = data.makeAdminAccount.error as MakeAdminAccountError;
            message.error(error.message);
          } else {
            message.success("관리자 권한을 부여했습니다");
          }
        },
        refetchQueries: [{ query: UserDocument, variables: { userId: id } }],
      });
    } else if (accountRole === "CounselorAccount") {
      makeCounselorAccount({
        variables: {
          accountId: id,
        },
        onCompleted: (data) => {
          if (
            data.makeCounselorAccount.__typename === "MakeCounselorAccountFail"
          ) {
            const error = data.makeCounselorAccount
              .error as MakeCounselorAccountError;
            message.error(error.message);
          } else {
            message.success("메카닉 권한을 부여했습니다");
          }
        },
        refetchQueries: [{ query: UserDocument, variables: { userId: id } }],
      });
    } else if (accountRole === "BizAccount") {
      makeBizAccount({
        variables: {
          accountId: id,
        },
        onCompleted: (data) => {
          if (data.makeBizAccount.__typename === "MakeBizAccountFail") {
            const error = data.makeBizAccount.error as MakeBizAccountError;
            message.error(error.message);
          } else {
            message.success("비즈유저 권한을 부여했습니다");
          }
        },
        refetchQueries: [{ query: UserDocument, variables: { userId: id } }],
      });
    } else if (accountRole === "UserAccount") {
      makeUserAccount({
        variables: {
          accountId: id,
        },
        onCompleted: (data) => {
          if (data.makeUserAccount.__typename === "MakeUserAccountFail") {
            const error = data.makeUserAccount.error as MakeUserAccountError;
            message.error(error.message);
          } else {
            message.success("일반 유저 권한을 부여했습니다");
          }
        },
        refetchQueries: [{ query: UserDocument, variables: { userId: id } }],
      });
    } else {
      message.error("유효하지 않은 권한입니다: " + accountRole);
    }
  };

  const unreadMessageCount =
    data?.user.activeCounsels.reduce((acc, cur) => {
      return acc + cur.unreadCount;
    }, 0) || 0;

  return (
    <Layout className="content-container">
      <Title level={2}>유저 상세</Title>
      {loading && <Loading />}
      {error && (
        <div>
          에러 발생: <span>{error.message}</span>
        </div>
      )}
      {!id && (
        <Result
          status="404"
          title={id + "의 ID를 가지고 있는 유저를 찾을 수 없습니다"}
        />
      )}
      {data && (
        <>
          <Title level={3}>유저 기본 정보</Title>
          <Descriptions bordered>
            <Descriptions.Item label="고유ID" span={1}>
              {data.user.id}
            </Descriptions.Item>
            <Descriptions.Item label="닉네임" span={1}>
              {data.user.nickname}
            </Descriptions.Item>
            <Descriptions.Item label="이름" span={1}>
              {data.user.name}
            </Descriptions.Item>
            <Descriptions.Item label="메일" span={1}>
              {data.user.email}
            </Descriptions.Item>
            <Descriptions.Item label="연락처" span={1}>
              {data.user.phone}
            </Descriptions.Item>
            <Descriptions.Item label="역할" span={1}>
              <Text code>{accountRoleFormatter(data.account?.__typename)}</Text>
            </Descriptions.Item>
            {data.user.lastDevice && (
              <Descriptions.Item label="최종 디바이스" span={3}>
                앱버전: {data.user.lastDevice.appVersion}
                <br />
                브랜드: {data.user.lastDevice.brand}
                <br />
                모델: {data.user.lastDevice.model}
                <br />
                OS: {data.user.lastDevice.os}
                <br />
                OS 버전: {data.user.lastDevice.osVersion}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="알림 카운트" span={1}>
              unreadNotificationCount: {data.user.unreadNotificationCount || 0}
              <br />
              unreadMessageCount: {unreadMessageCount}
              <br />
              badgeCount (iOS only):{" "}
              {unreadMessageCount + (data.user.unreadNotificationCount || 0)}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <div>
                  최근 10개 알림
                  <br />
                  NotificationIds
                </div>
              }
              span={1}
            >
              {data.user.notificationConnection.edges.map((edge) => {
                return (
                  <div key={edge.node.id}>
                    <Text code>{edge.node.id}</Text>
                    {edge.node.viewed ? "읽음" : "읽지 않음"}
                  </div>
                );
              })}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Title level={3}>유저 권한 정보</Title>
          <Space>
            <Radio.Group
              value={accountRole}
              onChange={(e) => {
                setAccountRole(e.target.value);
              }}
            >
              <Radio value={"UserAccount"}>유저</Radio>
              <Radio value={"BizAccount"}>비즈유저</Radio>
              <Radio value={"CounselorAccount"}>메카닉</Radio>
              <Radio value={"AdminAccount"}>관리자</Radio>
            </Radio.Group>
            {accountRole !== data.account?.__typename && (
              <Popconfirm
                title={`유저의 권한을 변경하시겠습니까?`}
                description="메카닉, 관리자 권한은 신중하게 부여해주세요."
                onConfirm={changeRole}
              >
                <Button type="primary">권한 변경</Button>
              </Popconfirm>
            )}
          </Space>
          <Divider />
          <Title level={3}>작성한 게시글 목록</Title>
          {data?.user.postConnection && (
            <Table
              columns={postColumns}
              dataSource={data.user.postConnection.edges.map(
                (edge) => edge.node
              )}
              pagination={{
                hideOnSinglePage: true,
              }}
              rowKey="id"
              footer={() => (
                <Space>
                  <Text>
                    총 {data.user.postCount}개의 게시글,{" "}
                    {data.user.commentCount}의 댓글이 있습니다.
                  </Text>
                  <Text type="secondary">
                    표 내에는 최근 10개의 게시글만 표시됩니다
                  </Text>
                </Space>
              )}
            />
          )}
          <Divider />
          <Title level={3}>진행중인 상담 목록</Title>
          {data?.user.activeCounsels && (
            <Table
              columns={activeCounselColumns}
              dataSource={data.user.activeCounsels}
              pagination={{ hideOnSinglePage: true }}
              rowKey="id"
            />
          )}
          <Divider />
          <Title level={3}>차량 목록</Title>
          {data?.user.vehicles && (
            <Table
              columns={vehicleColumns as any}
              dataSource={data.user.vehicles}
              pagination={{ hideOnSinglePage: true }}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default UserPage;
