import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  Input,
  Typography,
  notification,
  Layout,
  theme,
} from "antd";
import styled from "styled-components";

import app from "../../../package.json";
import { ReactComponent as DarkLobby } from "../../assets/image/darkLobby.svg";
import { ReactComponent as LightLobby } from "../../assets/image/lightLobby.svg";

import logo from "src/assets/image/logo.png";
import { loggedInVar } from "src/utils/cache";
import { useLoginMutation } from "src/utils/client";
import { useDarkMode } from "src/utils/providers";

gql`
  mutation login($email: String!, $password: String!) {
    localLogin(email: $email, password: $password) {
      accessToken
      expiresAt
    }
  }
`;

interface AuthFormInputs {
  email: string;
  password: string;
}

const { Text, Paragraph } = Typography;

const Auth = () => {
  const [loginMutation] = useLoginMutation({
    onError: (error) => {
      notification.error({
        message: "로그인 실패",
        description: error.message,
      });
    },
    onCompleted: (data) => {
      if (data.localLogin) {
        const accessToken = data.localLogin.accessToken;
        localStorage.setItem("token", accessToken);
        loggedInVar(true);
      }
    },
  });

  const [form] = Form.useForm<AuthFormInputs>();

  const onLogin = async ({ email, password }: AuthFormInputs) => {
    await loginMutation({
      variables: {
        email,
        password,
      },
    });
  };

  const { dark, setDark } = useDarkMode();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "500px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: colorBgContainer + "80",
            backdropFilter: "blur(10px)",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
            borderRadius: 32,
          }}
        >
          <SBody>
            <Text>Ver {app.version}</Text>
            <SLogo src={logo} alt="닥터차 로고" />
            <Typography.Title style={{ marginBottom: 40 }}>
              닥터차
              <br />
              관리자 콘솔
            </Typography.Title>
            <Form form={form} onFinish={onLogin} size="large">
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "이메일 주소를 입력해주세요!" },
                ]}
              >
                <Input
                  type="email"
                  placeholder="이메일 주소"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "비밀번호를 입력해주세요!" },
                ]}
              >
                <Input
                  type="password"
                  placeholder="비밀번호"
                  prefix={<LockOutlined />}
                />
              </Form.Item>
              <Form.Item>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button
                    onClick={() => {
                      setDark(!dark);
                    }}
                    icon={
                      dark ? (
                        <FontAwesomeIcon icon={faMoon} />
                      ) : (
                        <FontAwesomeIcon icon={faSun} />
                      )
                    }
                  />
                  <Button style={{ flex: 1 }} type="primary" htmlType="submit">
                    로그인
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </SBody>
          <Paragraph
            style={{
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "5rem",
            }}
          >
            Copyright ©{new Date().getFullYear()} Produced by Autopedia
          </Paragraph>
        </div>
      </div>
      {dark ? <DarkLobby /> : <LightLobby />}
    </Layout>
  );
};

const SBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 3rem 4rem;
`;

const SLogo = styled.img`
  width: 87px;
  height: 48px;
  margin-top: 55px;
  margin-bottom: 30px;
`;

export default Auth;
