/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dayjs from "dayjs";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./App";

import { GlobalProvider } from "src/utils/providers";

require("dayjs/locale/ko");

dayjs.locale("ko");

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
root.render(
  <GlobalProvider>
    <App />
  </GlobalProvider>
);
