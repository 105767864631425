import { gql } from "@apollo/client";
import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  message,
  Space,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFileUpload } from "src/hooks/useFileUpload";
import { FileType, useCreateNoticeMutation } from "src/utils/client";

const { Title, Paragraph } = Typography;

gql`
  mutation CreateNotice($data: CreateNoticeData!) {
    createNotice(data: $data) {
      notice {
        id
      }
    }
  }
`;

type CreateNoticeForm = {
  title: string;
  body: string;
  files: string[];
};

const CreateNotice = () => {
  const { id } = useParams();
  const [createNoticeMutation, { loading }] = useCreateNoticeMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm<CreateNoticeForm>();
  const { upload } = useFileUpload();

  const handleUpload = ({ file, onSuccess, onError }: UploadRequestOption) => {
    if (file instanceof File) {
      upload(file)
        .then((res) => {
          onSuccess?.(res, new XMLHttpRequest());
        })
        .catch(onError);
    }
  };

  const handleUpdateNotice = async () => {
    const files = form.getFieldValue("files") as UploadFile[] | undefined;
    const fileData = files?.map((file) => {
      return {
        type: file.response.type === "image" ? FileType.Image : FileType.Video,
        url: file.response?.url,
      };
    });
    await createNoticeMutation({
      variables: {
        data: {
          title: form.getFieldValue("title"),
          body: form.getFieldValue("body"),
          files: fileData,
        },
      },
      onCompleted: (data) => {
        message.success(
          "공지사항이 등록되었습니다. 발급ID: " + data.createNotice.notice.id
        );
        navigate(`/notices/${data.createNotice.notice.id}`);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <Layout className="content-container">
      <Title level={2}>공지사항 수정</Title>

      <Form
        form={form}
        labelCol={{ span: 1 }}
        disabled={loading}
        onFinish={handleUpdateNotice}
      >
        <Form.Item
          label="제목"
          name="title"
          rules={[
            { required: true, message: "제목을 꼭 입력해주세요" },
            { min: 2, message: "제목은 2글자 이상이어야 합니다." },
          ]}
        >
          <Input placeholder="제목을 적어주세요" />
        </Form.Item>
        <Form.Item
          label="본문"
          name="body"
          rules={[
            {
              required: true,
              message: "본문을 꼭 입력해주세요",
            },
            { min: 10, message: "본문은 10글자 이상이어야 합니다." },
          ]}
        >
          <Input.TextArea
            placeholder="본문 내용을 적어주세요"
            autoSize
            minLength={3}
          />
        </Form.Item>
        <Form.Item
          label="파일"
          name="files"
          valuePropName="fileList"
          getValueFromEvent={(e: any) => {
            if (e.file.status === "removed") {
              const images = form.getFieldValue("files");
              const filtered = images.filter(
                (image: any) => image.url !== e.file.url
              );
              form.setFieldValue("files", filtered);
              return filtered;
            }

            return Array.isArray(e) ? e : e?.fileList || [];
          }}
        >
          <Upload
            multiple
            customRequest={handleUpload}
            accept="image/*"
            listType="picture-card"
          >
            + 추가
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 1 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              공지사항 등록
            </Button>
            <Button onClick={() => navigate(`/notices`)}>취소</Button>
          </Space>
          <Paragraph style={{ paddingTop: "1rem" }} type="secondary">
            * 한번 생성된 공지사항은 수정은 가능하나, 삭제할 수 없습니다.
            신중하게 등록해주세요.
          </Paragraph>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default CreateNotice;
