import { gql } from "@apollo/client";
import {
  Button,
  Form,
  Input,
  Layout,
  message,
  Result,
  Space,
  Spin,
  Typography,
  Upload,
} from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFileUpload } from "src/hooks/useFileUpload";
import {
  useNoticeQuery,
  useUpdateNoticeMutation,
  FileType,
} from "src/utils/client";

const { Title } = Typography;

gql`
  mutation UpdateNotice($data: UpdateNoticeData!, $noticeId: ID!) {
    updateNotice(data: $data, noticeId: $noticeId) {
      ... on UpdateNoticeSuccess {
        notice {
          id
          title
          body
          files {
            url
          }
        }
      }
      ... on UpdateNoticeFail {
        error {
          message
        }
      }
    }
  }
`;

type UpdateNoticeForm = {
  title: string;
  body: string;
  files: string[];
};

const UpdateNotice = () => {
  const { id } = useParams();
  const { data, loading, error } = useNoticeQuery({
    skip: !id,
    variables: { noticeId: id || "" },
  });
  const [updateNoticeMutation, { loading: updateLoading }] =
    useUpdateNoticeMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm<UpdateNoticeForm>();
  const { upload } = useFileUpload();

  const handleUpload = ({ file, onSuccess, onError }: UploadRequestOption) => {
    if (file instanceof File) {
      upload(file)
        .then((res) => {
          onSuccess?.(res, new XMLHttpRequest());
        })
        .catch(onError);
    }
  };

  const handleUpdateNotice = () => {
    const files = form.getFieldValue("files") as any[];
    const fileData = files.map((file) => {
      if (file.url) {
        return {
          type: file.__typename === "Image" ? FileType.Image : FileType.Video,
          url: file.url,
        };
      } else {
        return {
          type:
            file.response.type === "image" ? FileType.Image : FileType.Video,
          url: file.response?.url,
        };
      }
    });
    updateNoticeMutation({
      variables: {
        noticeId: id || "",
        data: {
          title: form.getFieldValue("title"),
          body: form.getFieldValue("body"),
          files: fileData,
        },
      },
      onCompleted: (data) => {
        if (data.updateNotice.__typename === "UpdateNoticeSuccess") {
          navigate(`/notices/${id}`);
        } else if (data.updateNotice.__typename === "UpdateNoticeFail") {
          message.error(JSON.stringify(data.updateNotice.error));
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <Layout className="content-container">
      <Title level={2}>공지사항 수정</Title>
      <Spin spinning={loading}>
        {error && (
          <Result
            status="error"
            title="공지사항을 불러오는데 실패했습니다."
            subTitle={error.message}
          />
        )}
        {data?.notice && (
          <Form
            form={form}
            initialValues={data.notice}
            labelCol={{ span: 1 }}
            onFinish={handleUpdateNotice}
          >
            <Form.Item
              label="제목"
              name="title"
              rules={[
                { required: true, message: "제목을 꼭 입력해주세요" },
                { min: 2, message: "제목은 2글자 이상이어야 합니다." },
              ]}
            >
              <Input placeholder="제목을 적어주세요" />
            </Form.Item>
            <Form.Item
              label="본문"
              name="body"
              rules={[
                {
                  required: true,
                  message: "본문을 꼭 입력해주세요",
                },
                { min: 10, message: "본문은 10글자 이상이어야 합니다." },
              ]}
            >
              <Input.TextArea
                placeholder="본문 내용을 적어주세요"
                autoSize
                minLength={3}
              />
            </Form.Item>
            <Form.Item
              label="파일"
              name="files"
              valuePropName="fileList"
              getValueFromEvent={(e: any) => {
                if (e.file.status === "removed") {
                  const images = form.getFieldValue("files");
                  const filtered = images.filter(
                    (image: any) => image.url !== e.file.url
                  );
                  form.setFieldValue("files", filtered);
                  return filtered;
                }

                return Array.isArray(e) ? e : e?.fileList || [];
              }}
            >
              <Upload
                multiple
                customRequest={handleUpload}
                accept="image/*"
                listType="picture-card"
              >
                + 추가
              </Upload>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 1 }}>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateLoading}
                >
                  수정
                </Button>
                <Button onClick={() => navigate(`/notices/${id}`)}>취소</Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Layout>
  );
};

export default UpdateNotice;
