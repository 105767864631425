export const selectFile = async (types?: string[]): Promise<File> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.name = "file";
    input.accept = types ? types.join(", ") : "*";

    input.onchange = (ev) => {
      const event = ev as unknown as React.ChangeEvent<HTMLInputElement>;
      const file = event.target.files?.[0];

      if (file) resolve(file);
      else reject("Cannot find a file");
    };

    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
  });
};

const getDataUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const url = event.target?.result;
      if (typeof url === "string") {
        resolve(url);
      } else {
        reject(null);
      }
    };
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};

export const readImageFile = async (
  file: File
): Promise<{ url: string; width: number; height: number }> => {
  let dataUrl: string;
  try {
    dataUrl = await getDataUrl(file);
  } catch (error) {
    throw new Error("파일을 읽을 수 없습니다.");
  }

  const image = await new Promise<{
    width: number;
    height: number;
  }>((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
    image.onerror = (error) => reject(error);

    image.src = dataUrl;
  });

  return { url: dataUrl, ...image };
};

export const readVideoFile = async (
  file: File
): Promise<{
  url: string;
  width: number;
  height: number;
  duration: number;
}> => {
  let dataUrl: string;
  try {
    dataUrl = await getDataUrl(file);
  } catch (error) {
    throw new Error("파일을 읽을 수 없습니다.");
  }

  const video = await new Promise<{
    width: number;
    height: number;
    duration: number;
  }>((resolve, reject) => {
    const video = document.createElement("video");

    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: Math.floor(video.duration * 1000),
      });
    };
    video.onerror = (error) => reject(error);

    video.preload = "metadata";
    video.src = dataUrl;
  });

  return { url: dataUrl, ...video };
};
