import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Button,
  Result,
  Space,
  Switch,
  Typography,
  Table,
  TableColumnsType,
  Input,
  Layout,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MediaFilesPreview from "src/components/MediaFilesPreview";
import { useMe } from "src/hooks/useMe";
import {
  ComplaintsOrder,
  ComplaintsQuery,
  useComplaintsLazyQuery,
} from "src/utils/client";
import { openCounselWindow } from "src/utils/external";

gql`
  query Complaints(
    $filter: ComplaintsFilter!
    $limit: Int!
    $offset: Int!
    $orderBy: ComplaintsOrder!
  ) {
    complaints(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      id
      author {
        id
        nickname
        avatar {
          url
        }
      }

      body
      title
      counsel {
        id
        index
      }
      files {
        url
      }
      order {
        id
        createdAt
        payment {
          id
          completedAt
          amount
        }
      }
      response {
        id
        author {
          id
          nickname
          avatar {
            url
          }
        }
        files {
          url
        }
        body
        viewed
        createdAt
      }
      createdAt
    }
  }
`;

const { Text, Title } = Typography;

const columns: TableColumnsType<ComplaintsQuery["complaints"][number]> = [
  {
    title: "문의ID",
    dataIndex: "id",
    width: 230,
    key: "id",
    render: (_, { id }) => <Link to={`/complaints/${id}`}>{id}</Link>,
  },
  {
    title: "작성자",
    dataIndex: "author",
    key: "author",
    width: 210,
    render: (_, { author }) =>
      author && (
        <Link to={`/users/${author.id}`}>
          <Space>
            {author.avatar?.url ? (
              <Avatar src={author.avatar?.url} />
            ) : (
              <Avatar>{author.nickname[0]}</Avatar>
            )}
            {author.nickname}
          </Space>
        </Link>
      ),
  },
  {
    title: "본문",
    dataIndex: "body",
    key: "body",
    render: (_, { title, body }) => (
      <>
        {title && <Title level={5}>{title}</Title>}
        <Text>{body}</Text>
      </>
    ),
  },
  {
    title: "상담",
    dataIndex: "counsel",
    key: "counsel",
    width: 90,
    render: (_, { counsel }) =>
      counsel && (
        <Button
          onClick={() => {
            openCounselWindow(counsel.id);
          }}
        >
          상담창
        </Button>
      ),
  },
  {
    title: "파일",
    dataIndex: "files",
    key: "files",
    width: 110,
    ellipsis: true,
    render: (files: { url: string }[]) => (
      <MediaFilesPreview files={files} height={36} />
    ),
  },
  {
    title: "주문",
    dataIndex: "order",
    key: "order",
    width: 100,
    render: (_, { order }) =>
      order && (
        <Button type="link" disabled>
          {order.payment?.amount}원
        </Button>
      ),
  },
  {
    title: "문의생성일",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 180,
    render: (_, { createdAt }) => (
      <Text>{dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")}</Text>
    ),
  },
  {
    title: "문의응답자",
    dataIndex: "response",
    key: "response",
    width: 210,
    render: (_, { response, id }) =>
      response && (
        <Link to={`/complaints/${id}`}>
          <Space>
            <Avatar src={response.author?.avatar?.url} />
            <Text>{response.author?.nickname}</Text>
          </Space>
        </Link>
      ),
  },
];

const PAGE_SIZE = 20;

const Complaints = () => {
  const [complaintOrder, setComplaintOrder] = useState<ComplaintsOrder>(
    ComplaintsOrder.CreatedAtDesc
  );
  const [responsed, setResponsed] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const navigate = useNavigate();
  const [complaintsQuery, { data, loading, error }] = useComplaintsLazyQuery();
  const { me } = useMe();

  useEffect(() => {
    complaintsQuery({
      variables: {
        filter: {
          responsed,
        },
        offset: (page - 1) * pageSize,
        limit: pageSize,
        orderBy: complaintOrder,
      },
    });
  }, [complaintOrder, responsed, complaintsQuery, page, pageSize, me]);

  return (
    <Layout className="content-container">
      <Title level={2}>고객만족센터 문의 목록</Title>
      <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
        <Input.Search
          size="large"
          style={{ flex: 1 }}
          placeholder="고객만족센터문의 ID 입력"
          onSearch={(value) => {
            navigate(`/complaints/${value}`);
          }}
        />
        <Switch
          checkedChildren={
            <span>
              <ArrowDownOutlined /> 날짜정렬
            </span>
          }
          unCheckedChildren={
            <span>
              <ArrowUpOutlined /> 날짜정렬
            </span>
          }
          checked={complaintOrder === ComplaintsOrder.CreatedAtDesc}
          onChange={(checked) => {
            if (checked) {
              setComplaintOrder(ComplaintsOrder.CreatedAtDesc);
            } else {
              setComplaintOrder(ComplaintsOrder.CreatedAtAsc);
            }
          }}
        />
        <Switch
          checkedChildren={"응답된 답변만"}
          unCheckedChildren={"모든 답변"}
          onChange={(checked) => {
            setResponsed(checked);
          }}
        />
      </div>
      {error && (
        <Result
          status={"error"}
          title="정보 로드에 문제가 발생했습니다"
          subTitle={error.message}
        />
      )}
      <Table
        bordered
        loading={loading}
        columns={columns as any}
        dataSource={data?.complaints}
        sticky
        pagination={{
          position: ["topLeft", "bottomLeft"],
          total: 999,
          current: page,
          pageSize,
          onShowSizeChange: async (current, size) => {
            setPageSize(size);
          },
          onChange: async (page) => {
            setPage(page);
          },
        }}
        size={"small"}
        rowKey="id"
      />
    </Layout>
  );
};

export default Complaints;
