import { Spin, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const { Text } = Typography;

const Loading: React.FC = () => {
  return (
    <SContainer>
      <Spin size="large" style={{ margin: 40 }} />
      <Text disabled>로딩중...</Text>
    </SContainer>
  );
};

const SContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Loading;
