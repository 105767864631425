import { gql } from "@apollo/client";
import { Descriptions, Divider, Input, Layout, Typography } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import Loading from "src/components/Loading";
import { useVehicleLazyQuery } from "src/utils/client";
import { fuelTypeFormatter } from "src/utils/formatter";

gql`
  query Vehicle($vehicleId: ID!) {
    vehicle(vehicleId: $vehicleId) {
      id
      plateNumber
      vin
      fuelType {
        base
        hybrid
      }
      registeredYear
      mileage
      characteristics {
        id
        name
      }
      brand {
        id
        logo {
          url
        }
        name
        domestic
      }
      fullModelName
    }
  }
`;

const { Title } = Typography;

const VehiclePage: React.FC = () => {
  const [vehicleQuery, { loading, error, data }] = useVehicleLazyQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const vehicleId = searchParams.get("vehicleId");

  React.useEffect(() => {
    if (vehicleId) {
      vehicleQuery({ variables: { vehicleId } });
    }
  }, [vehicleId, vehicleQuery]);

  return (
    <Layout className="content-container">
      <Title level={2}>차량 조회</Title>
      <Input.Search
        size="large"
        placeholder="차량 ID 입력"
        defaultValue={vehicleId || ""}
        onSearch={(value) => {
          setSearchParams({ vehicleId: value });
        }}
        alt="차량 ID 입력"
      />
      {loading && <Loading />}
      {error && <div>{error.message}</div>}
      {data?.vehicle && (
        <div>
          <Divider />
          <Title level={3}>차량 기본 정보</Title>
          <Descriptions bordered>
            <Descriptions.Item label="브랜드">
              {data.vehicle.brand.logo ? (
                <img
                  width={40}
                  alt={data.vehicle.brand.name}
                  src={data.vehicle.brand.logo.url}
                />
              ) : null}
              {data.vehicle.brand.name} (
              {data.vehicle.brand.domestic ? "국산" : "수입"}제조사)
            </Descriptions.Item>
            <Descriptions.Item label="모델명">
              {data.vehicle.fullModelName}
            </Descriptions.Item>
            <Descriptions.Item label="차량번호">
              {data.vehicle.plateNumber}
            </Descriptions.Item>
            <Descriptions.Item label="차대번호">
              {data.vehicle.vin}
            </Descriptions.Item>
            <Descriptions.Item label="연식">
              {data.vehicle.registeredYear}
            </Descriptions.Item>
            <Descriptions.Item label="주행거리">
              {data.vehicle.mileage}
            </Descriptions.Item>
            <Descriptions.Item label="연료">
              {data.vehicle.fuelType
                ? fuelTypeFormatter(data.vehicle.fuelType)
                : "(알 수 없음)"}
            </Descriptions.Item>
            <Descriptions.Item label="차량 속성">
              {data.vehicle.characteristics
                ?.map((characteristic) => {
                  return characteristic.name;
                })
                .join(", ")}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </Layout>
  );
};

export default VehiclePage;
