import { Fuel, Insurance, InsuranceEnum } from "./client";

export type Badge = {
  text: string;
  color: string | undefined;
};

export const fuelTypeFormatter = (fuelType: {
  base: Fuel;
  hybrid: boolean;
}) => {
  let string;

  switch (fuelType.base) {
    case Fuel.Diesel:
      string = "디젤";
      break;
    case Fuel.Electricity:
      string = "전기";
      break;
    case Fuel.Extra:
      string = "기타";
      break;
    case Fuel.Gasoline:
      string = "휘발유";
      break;
    case Fuel.Hydrogen:
      string = "수소 전지";
      break;
    case Fuel.Lpg:
      string = "LPG";
      break;
  }

  if (fuelType.hybrid) {
    string += " 하이브리드";
  }

  return string;
};

export const accountRoleColorFormatter = (
  __typename?:
    | "BizAccount"
    | "UserAccount"
    | "CounselorAccount"
    | "AdminAccount"
) => {
  switch (__typename) {
    case "BizAccount":
      return "orange";
    case "UserAccount":
      return "default";
    case "CounselorAccount":
      return "blue";
    case "AdminAccount":
      return "purple";
    default:
      return "default";
  }
};

export const accountRoleFormatter = (
  __typename?:
    | "BizAccount"
    | "UserAccount"
    | "CounselorAccount"
    | "AdminAccount"
) => {
  switch (__typename) {
    case "BizAccount":
      return "비즈유저";
    case "UserAccount":
      return "일반유저";
    case "CounselorAccount":
      return "메카닉";
    case "AdminAccount":
      return "관리자";
    default:
      return "알 수 없음";
  }
};

export const insuranceTypeFormatter = (
  insurance: Insurance | InsuranceEnum | undefined
) => {
  switch (insurance) {
    case InsuranceEnum.Cdw:
      return {
        text: "자차보험",
        color: "orange",
      };
    case InsuranceEnum.Lp:
      return {
        text: "대물보험",
        color: "blue",
      };
    case InsuranceEnum.None:
      return {
        text: "자비처리",
        color: "red",
      };
    default:
      return {
        text: "추천 희망",
        color: "default",
      };
  }
};
