import { EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Card,
  Descriptions,
  Divider,
  Layout,
  Pagination,
  Result,
  Segmented,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  DatePicker,
  Row,
  Col,
  Statistic,
} from "antd";
import { SegmentedValue } from "antd/es/segmented";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

import EstimateRequestPreview from "src/components/EstimateRequestPreview";
import MediaFilesPreview from "src/components/MediaFilesPreview";
import {
  EstimateInquirySortOrderEnum,
  useEstimateInquiriesCountQuery,
  useEstimateInquiriesLazyQuery,
} from "src/utils/client";
import { insuranceTypeFormatter } from "src/utils/formatter";

gql`
  query EstimateInquiriesCount($filter: EstimateInquiryFilter!) {
    estimateInquiryCount(filter: $filter)
  }
`;

gql`
  query EstimateInquiries(
    $limit: Int!
    $offset: Int!
    $sortOrder: EstimateInquirySortOrderEnum!
    $filter: EstimateInquiryFilter!
  ) {
    estimateInquiryCount(filter: $filter)
    estimateInquiries(
      limit: $limit
      offset: $offset
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      client {
        name
        phone
        account {
          id
          profile {
            avatarImage {
              url
            }
          }
        }
      }
      detail {
        body
        files {
          url
        }
        areas {
          radius
          name
          zone {
            province
            district
            neighborhood
          }
        }
        insurance
      }
      vehicle {
        modelName
        modelYear
        plateNumber
        vin
      }
      estimateRequests {
        ...EstimateRequestPreview
      }
      createdAt
    }
  }
`;

export type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

const PAGE_SIZE = 10;

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const EstimateInquiries = () => {
  const [order, setOrder] = React.useState<EstimateInquirySortOrderEnum>(
    EstimateInquirySortOrderEnum.CreatedAtDesc
  );
  const [hasUnpublishedEstimate, setHasUnpublishedEstimate] =
    React.useState<SegmentedValue>("false");

  const [dateRange, setDateRange] = React.useState<RangeValue | null>(null);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const [estimateInquiriesQuery, { data, loading, error }] =
    useEstimateInquiriesLazyQuery();

  const threeMonthsAgo = dayjs().subtract(3, "month").valueOf();
  const oneMonthAgo = dayjs().subtract(1, "month").valueOf();
  const oneWeekAgo = dayjs().subtract(1, "week").valueOf();
  const today = dayjs().valueOf();

  const {
    data: threeMonthsInquiriesCount,
    loading: threeMonthsInquiriesCountLoading,
  } = useEstimateInquiriesCountQuery({
    skip: !threeMonthsAgo,
    variables: {
      filter: {
        createdAt: {
          from: threeMonthsAgo,
          to: today,
        },
      },
    },
  });

  const {
    data: oneMonthInquiriesCount,
    loading: oneMonthInquiriesCountLoading,
  } = useEstimateInquiriesCountQuery({
    skip: !oneMonthAgo,
    variables: {
      filter: {
        createdAt: {
          from: oneMonthAgo,
          to: today,
        },
      },
    },
  });

  const { data: oneWeekInquiriesCount, loading: oneWeekInquiriesCountLoading } =
    useEstimateInquiriesCountQuery({
      skip: !oneWeekAgo,
      variables: {
        filter: {
          createdAt: {
            from: oneWeekAgo,
            to: today,
          },
        },
      },
    });

  React.useEffect(() => {
    estimateInquiriesQuery({
      variables: {
        sortOrder: order,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        filter: {
          hasUnpublishedEstimate:
            hasUnpublishedEstimate === "true" ? true : false,
          createdAt: dateRange
            ? {
                from: dateRange[0]?.valueOf() as number,
                to: dateRange[1]?.valueOf() as number,
              }
            : undefined,
        },
      },
    });
  }, [
    page,
    pageSize,
    estimateInquiriesQuery,
    order,
    hasUnpublishedEstimate,
    dateRange,
  ]);

  return (
    <Layout className="content-container">
      <Title level={2}>견적 문의 목록</Title>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Card size="small">
            <Statistic
              loading={threeMonthsInquiriesCountLoading}
              title={"지난 3개월간 견적 문의"}
              value={threeMonthsInquiriesCount?.estimateInquiryCount}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small">
            <Statistic
              loading={oneMonthInquiriesCountLoading}
              title={"지난 1개월간 견적 문의"}
              value={oneMonthInquiriesCount?.estimateInquiryCount}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small">
            <Statistic
              loading={oneWeekInquiriesCountLoading}
              title={"지난 1주간 견적 문의"}
              value={oneWeekInquiriesCount?.estimateInquiryCount}
            />
          </Card>
        </Col>
      </Row>
      <Card size="small">
        <Space>
          <Text>발행 여부: </Text>
          <Segmented
            value={hasUnpublishedEstimate}
            onChange={(value) => {
              setHasUnpublishedEstimate(value);
            }}
            options={[
              {
                value: "false",
                label: "전체",
              },
              {
                value: "true",
                label: "발행 대기중",
              },
            ]}
            onResize={undefined}
            onResizeCapture={undefined}
          />
          <Divider type="vertical" />
          <Text>정렬 기준: </Text>
          <Segmented
            value={order}
            onChange={(value) => {
              setOrder(value as EstimateInquirySortOrderEnum);
            }}
            options={[
              {
                value: EstimateInquirySortOrderEnum.CreatedAtDesc,
                label: "최신순",
              },
              {
                value: EstimateInquirySortOrderEnum.CreatedAtAsc,
                label: "오래된순",
              },
            ]}
            onResize={undefined}
            onResizeCapture={undefined}
          />
          <Divider type="vertical" />
          <Text>기간: </Text>
          <RangePicker
            onChange={(dates) => {
              setDateRange(dates);
            }}
          />
          <Divider type="vertical" />
          <Text type="secondary">
            총 {data?.estimateInquiryCount}개의 견적문의 조회됨
          </Text>
        </Space>
      </Card>
      {error && (
        <Result
          status={"error"}
          title="정보 로드에 문제가 발생했습니다"
          subTitle={error.message}
        />
      )}
      <Spin spinning={loading}>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <Pagination
            current={page}
            pageSize={pageSize}
            total={data?.estimateInquiryCount}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />
          {data?.estimateInquiries.length ? (
            data.estimateInquiries.map((estimateInquiry) => (
              <Card
                title={
                  <Link to={`/estimate-inquiries/${estimateInquiry.id}`}>
                    <Text copyable underline>
                      {estimateInquiry.id}
                    </Text>
                  </Link>
                }
                key={estimateInquiry.id}
              >
                <Descriptions column={3} size="small" bordered>
                  <Descriptions.Item label="고객명">
                    {estimateInquiry.client.account ? (
                      <Tooltip title="닥터차 유저입니다">
                        <Space>
                          <Avatar
                            src={
                              estimateInquiry.client.account?.profile
                                .avatarImage?.url
                            }
                            icon={<UserOutlined />}
                          />
                          <Link
                            to={`/users/${estimateInquiry.client.account.id}`}
                          >
                            {estimateInquiry.client.name}
                          </Link>
                        </Space>
                      </Tooltip>
                    ) : (
                      <Text>{estimateInquiry.client.name}</Text>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="고객 전화번호">
                    <Text copyable>{estimateInquiry.client.phone}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="요청일">
                    <Text type="secondary">
                      {dayjs(estimateInquiry.createdAt).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="요청지역">
                    {estimateInquiry.detail.areas.map((area, i) => (
                      <Tag icon={<EnvironmentOutlined />} key={i}>
                        {area.zone?.province} {area.zone?.district}{" "}
                        {area.zone?.neighborhood} ({area.radius / 1000}km 반경)
                      </Tag>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item label="차량정보">
                    {estimateInquiry.vehicle?.modelName}{" "}
                    {estimateInquiry.vehicle?.modelYear}년식
                  </Descriptions.Item>
                  <Descriptions.Item label="보험여부">
                    <Tag
                      color={
                        insuranceTypeFormatter(estimateInquiry.detail.insurance)
                          .color
                      }
                    >
                      {
                        insuranceTypeFormatter(estimateInquiry.detail.insurance)
                          .text
                      }
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="요청내용">
                    <Text type="secondary">{estimateInquiry.detail.body}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="첨부자료">
                    <MediaFilesPreview
                      files={estimateInquiry.detail.files}
                      height={100}
                    />
                  </Descriptions.Item>
                </Descriptions>
                <Divider />
                <div style={{ display: "flex", gap: 12, flexWrap: "wrap" }}>
                  {estimateInquiry.estimateRequests.map((estimateRequest) => (
                    <EstimateRequestPreview
                      key={estimateRequest.id}
                      data={estimateRequest}
                    />
                  ))}
                </div>
              </Card>
            ))
          ) : (
            <Result status={"info"} title="조건에 해당하는 문의가 없습니다" />
          )}
          {!loading && (
            <Pagination
              current={page}
              pageSize={pageSize}
              total={data?.estimateInquiryCount}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }}
            />
          )}
        </div>
      </Spin>
    </Layout>
  );
};

export default EstimateInquiries;
