
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AcceptAppointmentError": [],
    "AcceptAppointmentResponse": [
      "AcceptAppointmentFail",
      "AcceptAppointmentSuccess"
    ],
    "Account": [
      "AdminAccount",
      "BizAccount",
      "CounselorAccount",
      "UserAccount"
    ],
    "AddMemberToCompanyError": [
      "CompanyWithMembersMustHaveAtLeastOneManagerError",
      "MemberLimitExceededError",
      "MemberWithIdAlreadyExistsError"
    ],
    "AddMemberToCompanyResponse": [
      "AddMemberToCompanyFail",
      "AddMemberToCompanySuccess"
    ],
    "AddWorkRepairError": [],
    "AddWorkRepairResponse": [
      "AddWorkRepairFail",
      "AddWorkRepairSuccess"
    ],
    "Appointment": [
      "AcceptedAppointment",
      "BrokenAppointment",
      "CancelledAppointment",
      "FinishedAppointment",
      "RequestedAppointment"
    ],
    "ApproveOrderResponse": [
      "ApproveOrderSuccess"
    ],
    "BlogPost": [
      "ExternalBlogPost"
    ],
    "BookmarkPostIssueResponse": [
      "BookmarkPostIssueSuccess"
    ],
    "BookmarkPostResponse": [
      "BookmarkPostSuccess"
    ],
    "BreakAppointmentError": [],
    "BreakAppointmentResponse": [
      "BreakAppointmentFail",
      "BreakAppointmentSuccess"
    ],
    "CancelAppointmentError": [],
    "CancelAppointmentResponse": [
      "CancelAppointmentFail",
      "CancelAppointmentSuccess"
    ],
    "ChangeCompanyMemberRoleError": [
      "CompanyWithMembersMustHaveAtLeastOneManagerError"
    ],
    "ChangeCompanyMemberRoleResponse": [
      "ChangeCompanyMemberRoleFail",
      "ChangeCompanyMemberRoleSuccess"
    ],
    "ChangeMyNotificationSettingError": [],
    "ChangeMyNotificationSettingResponse": [
      "ChangeMyNotificationSettingFail",
      "ChangeMyNotificationSettingSuccess"
    ],
    "ChangeMyPhoneError": [
      "PhoneHasNotVerifiedError"
    ],
    "ChangeMyPhoneResponse": [
      "ChangeMyPhoneFail",
      "ChangeMyPhoneSuccess"
    ],
    "ChangeMyProfileError": [],
    "ChangeMyProfileResponse": [
      "ChangeMyProfileFail",
      "ChangeMyProfileSuccess"
    ],
    "ChangePostIssueToDoneError": [],
    "ChangePostIssueToDoneResponse": [
      "ChangePostIssueToDoneFail",
      "ChangePostIssueToDoneSuccess"
    ],
    "ChangeWorkExpectedDateOfReleaseError": [],
    "ChangeWorkExpectedDateOfReleaseResponse": [
      "ChangeWorkExpectedDateOfReleaseFail",
      "ChangeWorkExpectedDateOfReleaseSuccess"
    ],
    "ChangeWorkRepairError": [],
    "ChangeWorkRepairResponse": [
      "ChangeWorkRepairFail",
      "ChangeWorkRepairSuccess"
    ],
    "CommentBase": [
      "ChatOpenedComment",
      "Comment",
      "DeletedComment"
    ],
    "CommunityReport": [
      "CommunityCommentReport",
      "CommunityPostReport",
      "CommunityReplyReport",
      "CommunityUserReport"
    ],
    "CompletePaymentResponse": [
      "PaymentCompleted",
      "PaymentUncompleted"
    ],
    "CompleteWorkError": [],
    "CompleteWorkResponse": [
      "CompleteWorkFail",
      "CompleteWorkSuccess"
    ],
    "CouponBase": [
      "OnSiteCoupon"
    ],
    "CouponBenefit": [
      "DiscountAmountBenefit"
    ],
    "CreateCommentError": [
      "DeletedPostError"
    ],
    "CreateCommentResponse": [
      "CreateCommentFail",
      "CreateCommentSuccess"
    ],
    "CreateCompanyError": [
      "CompanyWithTheSameNameAndContactExistsError"
    ],
    "CreateCompanyResponse": [
      "CreateCompanyFail",
      "CreateCompanySuccess"
    ],
    "CreateCounselError": [
      "DeletedVehicleOwnerError",
      "VehicleOwnerMismatchError"
    ],
    "CreateCounselResponse": [
      "CreateCounselFail",
      "CreateCounselSuccess"
    ],
    "CreateHashtagResponse": [
      "CreateHashtagSuccess"
    ],
    "CreateItemResponse": [
      "CreateItemFail",
      "CreateItemSuccess"
    ],
    "CreatePostResponse": [
      "CreatePostSuccess"
    ],
    "CreateQaAccountsError": [
      "AccountAlreadyExistsError"
    ],
    "CreateQaAccountsResponse": [
      "CreateQaAccountsFail",
      "CreateQaAccountsSuccess"
    ],
    "CreateReplyResponse": [
      "CreateReplySuccess"
    ],
    "CreateSearchableCompanyReviewKeywordsError": [],
    "CreateSearchableCompanyReviewKeywordsResponse": [
      "CreateSearchableCompanyReviewKeywordsFail",
      "CreateSearchableCompanyReviewKeywordsSuccess"
    ],
    "CreateVehicleError": [],
    "CreateVehicleResponse": [
      "CreateVehicleFail",
      "CreateVehicleSuccess"
    ],
    "CreateWorkError": [
      "DuplicatedWorkExistError"
    ],
    "CreateWorkResponse": [
      "CreateWorkFail",
      "CreateWorkSuccess"
    ],
    "DeleteCommentResponse": [
      "DeleteCommentSuccess"
    ],
    "DeleteCompanyError": [],
    "DeleteCompanyResponse": [
      "DeleteCompanyFail",
      "DeleteCompanySuccess"
    ],
    "DeleteCounselResponse": [
      "DeleteCounselSuccess"
    ],
    "DeletePostResponse": [
      "DeletePostSuccess"
    ],
    "DeleteSearchableCompanyReviewError": [],
    "DeleteSearchableCompanyReviewResponse": [
      "DeleteSearchableCompanyReviewFail",
      "DeleteSearchableCompanyReviewSuccess"
    ],
    "DeleteVehicleError": [],
    "DeleteVehicleResponse": [
      "DeleteVehicleFail",
      "DeleteVehicleSuccess"
    ],
    "EditEstimateError": [],
    "EditEstimateRequestMemoError": [],
    "EditEstimateRequestMemoResponse": [
      "EditEstimateRequestMemoFail",
      "EditEstimateRequestMemoSuccess"
    ],
    "EditEstimateResponse": [
      "EditEstimateFail",
      "EditEstimateSuccess"
    ],
    "EditSearchableCompanyReviewError": [],
    "EditSearchableCompanyReviewResponse": [
      "EditSearchableCompanyReviewFail",
      "EditSearchableCompanyReviewSuccess"
    ],
    "Error": [
      "DeletedPostError",
      "ItemExistsError"
    ],
    "File": [
      "Image",
      "Video"
    ],
    "FinishAppointmentError": [],
    "FinishAppointmentResponse": [
      "FinishAppointmentFail",
      "FinishAppointmentSuccess"
    ],
    "InquireEstimateError": [],
    "InquireEstimateResponse": [
      "InquireEstimateFail",
      "InquireEstimateSuccess"
    ],
    "Inquiry": [
      "Counsel",
      "Post"
    ],
    "LeaveSearchableCompanyReviewError": [],
    "LeaveSearchableCompanyReviewResponse": [
      "LeaveSearchableCompanyReviewFail",
      "LeaveSearchableCompanyReviewSuccess"
    ],
    "LoginError": [
      "AccountAlreadyExistsError",
      "EmailHasNotVerifiedError",
      "PhoneHasNotVerifiedError"
    ],
    "LoginResponse": [
      "LoginFail",
      "LoginSuccess"
    ],
    "MakeAdminAccountError": [],
    "MakeAdminAccountResponse": [
      "MakeAdminAccountFail",
      "MakeAdminAccountSuccess"
    ],
    "MakeBizAccountError": [],
    "MakeBizAccountResponse": [
      "MakeBizAccountFail",
      "MakeBizAccountSuccess"
    ],
    "MakeCounselorAccountError": [],
    "MakeCounselorAccountResponse": [
      "MakeCounselorAccountFail",
      "MakeCounselorAccountSuccess"
    ],
    "MakeUserAccountError": [],
    "MakeUserAccountResponse": [
      "MakeUserAccountFail",
      "MakeUserAccountSuccess"
    ],
    "Message": [
      "AppReviewMessage",
      "AreaInputMessage",
      "CompanyMessage",
      "DeletedMessage",
      "EndMessage",
      "EnterMessage",
      "EstimateMessage",
      "FileMessage",
      "InvoiceMessage",
      "LeaveMessage",
      "PlateNumberInputMessage",
      "QueueMessage",
      "RestartMessage",
      "SuspendedMessage",
      "TextMessage",
      "VinInputMessage"
    ],
    "Node": [
      "InquiryGuide",
      "InquiryType",
      "Item",
      "PostCategory",
      "User",
      "UserCharacteristic"
    ],
    "Notification": [
      "AppointmentAcceptedNotification",
      "AppointmentCancelledNotification",
      "AppointmentFinishedNotification",
      "AppointmentRequestedNotification",
      "AppointmentWillArriveAtChangedNotification",
      "CounselReviewNotification",
      "DayBeforeAppointmentNotification",
      "EstimateRequestedNotification",
      "NewCommentNotification",
      "NewComplaintResponseNotification",
      "NewReplyNotification",
      "StoreReviewForPostNotification",
      "TwoHoursBeforeAppointmentNotification"
    ],
    "OrderBase": [
      "InsurancedOrder",
      "NoneInsurancedOrder",
      "Order"
    ],
    "PublishEstimateError": [],
    "PublishEstimateResponse": [
      "PublishEstimateFail",
      "PublishEstimateSuccess"
    ],
    "ReactToCommentResponse": [
      "ReactToCommentSuccess"
    ],
    "ReactToPostResponse": [
      "ReactToPostSuccess"
    ],
    "RedeemCouponError": [
      "AlreadyUsedCouponError",
      "ExpiredCouponError"
    ],
    "RedeemCouponResponse": [
      "RedeemCouponFail",
      "RedeemCouponSuccess"
    ],
    "RejectOrderResponse": [
      "RejectOrderSuccess"
    ],
    "RemoveMemberFromCompanyError": [],
    "RemoveMemberFromCompanyResponse": [
      "RemoveMemberFromCompanyFail",
      "RemoveMemberFromCompanySuccess"
    ],
    "RemoveWorkRepairError": [],
    "RemoveWorkRepairResponse": [
      "RemoveWorkRepairFail",
      "RemoveWorkRepairSuccess"
    ],
    "ReportCommunityCommentError": [],
    "ReportCommunityCommentResponse": [
      "ReportCommunityCommentFail",
      "ReportCommunityCommentSuccess"
    ],
    "ReportCommunityPostError": [],
    "ReportCommunityPostResponse": [
      "ReportCommunityPostFail",
      "ReportCommunityPostSuccess"
    ],
    "ReportCommunityReplyError": [],
    "ReportCommunityReplyResponse": [
      "ReportCommunityReplyFail",
      "ReportCommunityReplySuccess"
    ],
    "ReportCommunityUserError": [],
    "ReportCommunityUserResponse": [
      "ReportCommunityUserFail",
      "ReportCommunityUserSuccess"
    ],
    "ReportSearchableCompanyReviewError": [],
    "ReportSearchableCompanyReviewResponse": [
      "ReportSearchableCompanyReviewFail",
      "ReportSearchableCompanyReviewSuccess"
    ],
    "RequestAppointmentError": [
      "DuplicatedAppointmentExistError"
    ],
    "RequestAppointmentResponse": [
      "RequestAppointmentFail",
      "RequestAppointmentSuccess"
    ],
    "RequestEstimateError": [],
    "RequestEstimateResponse": [
      "RequestEstimateFail",
      "RequestEstimateSuccess"
    ],
    "RequestIdentityVerificationForWetaxError": [
      "WetaxIdentityVerificationRequestFailError"
    ],
    "RequestIdentityVerificationForWetaxResponse": [
      "RequestIdentityVerificationForWetaxFail",
      "RequestIdentityVerificationForWetaxSuccess"
    ],
    "ResetPasswordError": [
      "AccountWithEmailDoesNotExistsError",
      "EmailHasNotVerifiedError"
    ],
    "ResetPasswordResponse": [
      "ResetPasswordFail",
      "ResetPasswordSuccess"
    ],
    "ResponseEstimateRequestError": [],
    "ResponseEstimateRequestResponse": [
      "ResponseEstimateRequestFail",
      "ResponseEstimateRequestSuccess"
    ],
    "SearchVehicleLedgerResponse": [
      "SearchedVehicleLedger",
      "VehicleLedgerServerDown"
    ],
    "SelectBestCommenterResponse": [
      "SelectBestCommenterSuccess"
    ],
    "ShareWorkError": [],
    "ShareWorkResponse": [
      "ShareWorkFail",
      "ShareWorkSuccess"
    ],
    "StopEstimationAndStartCounselError": [
      "NoEstimationToStopError"
    ],
    "StopEstimationAndStartCounselResponse": [
      "StopEstimationAndStartCounselFail",
      "StopEstimationAndStartCounselSuccess"
    ],
    "StopWorkError": [],
    "StopWorkResponse": [
      "StopWorkFail",
      "StopWorkSuccess"
    ],
    "SuggestSearchableCompanyEditionError": [],
    "SuggestSearchableCompanyEditionResponse": [
      "SuggestSearchableCompanyEditionFail",
      "SuggestSearchableCompanyEditionSuccess"
    ],
    "TaxRefundableAutomobilesResponse": [
      "IdentityNotVerifiedForWetax",
      "IdentityVerficationForWetaxNotRequested",
      "TaxRefundableAutomobiles"
    ],
    "TemplateMessage": [
      "FileMessageContent",
      "TextMessageContent"
    ],
    "UnbookmarkPostIssueResponse": [
      "UnbookmarkPostIssueSuccess"
    ],
    "UnbookmarkPostResponse": [
      "UnbookmarkPostSuccess"
    ],
    "UnpaidAutomobileTaxesResponse": [
      "IdentityNotVerifiedForWetax",
      "IdentityVerficationForWetaxNotRequested",
      "UnpaidAutomobileTaxes"
    ],
    "UnreactToCommentResponse": [
      "UnreactToCommentSuccess"
    ],
    "UnreactToPostResponse": [
      "UnreactToPostSuccess"
    ],
    "UpdateAppointmentError": [],
    "UpdateAppointmentResponse": [
      "UpdateAppointmentFail",
      "UpdateAppointmentSuccess"
    ],
    "UpdateCommentResponse": [
      "UpdateCommentSuccess"
    ],
    "UpdateCompanyError": [
      "CompanyWithTheSameNameAndContactExistsError"
    ],
    "UpdateCompanyResponse": [
      "UpdateCompanyFail",
      "UpdateCompanySuccess"
    ],
    "UpdateNoticeError": [],
    "UpdateNoticeResponse": [
      "UpdateNoticeFail",
      "UpdateNoticeSuccess"
    ],
    "UpdatePostIssueError": [],
    "UpdatePostIssueResponse": [
      "UpdatePostIssueFail",
      "UpdatePostIssueSuccess"
    ],
    "UpdatePostResponse": [
      "UpdatePostSuccess"
    ],
    "UpdateVehicleError": [],
    "UpdateVehicleResponse": [
      "UpdateVehicleFail",
      "UpdateVehicleSuccess"
    ],
    "VerifyEmailError": [
      "InvalidEmailVerificationCodeError"
    ],
    "VerifyEmailResponse": [
      "VerifyEmailFail",
      "VerifyEmailSuccess"
    ],
    "ViewAllMyNotificationsError": [],
    "ViewAllMyNotificationsResponse": [
      "ViewAllMyNotificationsFail",
      "ViewAllMyNotificationsSuccess"
    ],
    "ViewCommentResponse": [
      "ViewCommentSuccess"
    ],
    "ViewNotificationError": [],
    "ViewNotificationResponse": [
      "ViewNotificationFail",
      "ViewNotificationSuccess"
    ],
    "ViewPostResponse": [
      "ViewPostSuccess"
    ],
    "Vote": [
      "CommentVote",
      "PostVote"
    ],
    "VoteToCommentResponse": [
      "VoteToCommentSuccess"
    ],
    "VoteToPostResponse": [
      "VoteToPostSuccess"
    ],
    "WithdrawMeError": [],
    "WithdrawMeResponse": [
      "WithdrawMeFail",
      "WithdrawMeSuccess"
    ]
  }
};
      export default result;
    