import { gql } from "@apollo/client";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Space,
  Typography,
  Layout,
  Menu,
  theme,
  Divider,
  Badge,
} from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import packageJson from "../../package.json";

import { useMe } from "src/hooks/useMe";
import { usePageTemplateQuery } from "src/utils/client";
import { useDarkMode } from "src/utils/providers";

gql`
  query PageTemplate($filter: EstimateFilter) {
    estimateCount(filter: $filter)
  }
`;

type PageTemplateProps = {
  children: React.ReactNode;
};

const { Text, Title } = Typography;

const PageTemplate: React.FC<PageTemplateProps> = ({ children }) => {
  const { me, logout } = useMe();
  const { dark, setDark } = useDarkMode();
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = usePageTemplateQuery({
    variables: {
      filter: {
        hasPublished: false,
      },
    },
  });

  // remove the leading slash
  const currentKey = location.pathname.slice(1);

  const items: ItemType[] = [
    {
      type: "divider",
    },
    {
      label: "견적 시스템",
      key: "estimate",
      children: [
        { label: "견적문의 목록", key: "estimate-inquiries" },
        { label: "업체 견적요청 관리", key: "estimate-requests" },
        {
          label: (
            <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
              <Text>견적서 목록</Text>
              <Badge count={data?.estimateCount} />
            </div>
          ),
          key: "estimates",
        },
      ],
      type: "group",
    },
    {
      type: "divider",
    },
    {
      label: "서비스 관리",
      key: "services",
      children: [
        { label: "고객센터 관리", key: "complaints" },
        { label: "공지사항 관리", key: "notices" },
      ],
      type: "group",
    },
    {
      type: "divider",
    },
    {
      label: "정보 조회",
      key: "info",
      children: [
        { label: "유저 조회", key: "users" },
        { label: "차량 조회", key: "vehicles" },
        { label: "정비소 리뷰 조회", key: "reviews" },
      ],
      type: "group",
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ display: "flex", height: "100vh" }}>
      <Layout.Sider
        style={{
          background: colorBgContainer,
          minHeight: "100vh",
        }}
      >
        <ProfileContainer>
          <div style={{ marginBottom: "1rem" }}>
            <Text type="secondary">ver{packageJson.version}</Text>
          </div>
          <Title level={2}>
            닥터차
            <br />
            어드민 콘솔
          </Title>
          <Divider />
          <Space>
            <Button
              onClick={() => {
                setDark(!dark);
              }}
              icon={
                dark ? (
                  <FontAwesomeIcon icon={faMoon} />
                ) : (
                  <FontAwesomeIcon icon={faSun} />
                )
              }
            />
            <Button
              danger
              style={{ flex: 1 }}
              onClick={() => {
                logout();
              }}
            >
              로그아웃
            </Button>
          </Space>
          <AvatarContainer>
            <Avatar src={me?.avatar?.url}>{me?.nickname[0]}</Avatar>
            <Text>{me?.nickname}</Text>
          </AvatarContainer>
        </ProfileContainer>
        <Menu
          mode={"inline"}
          onClick={(info) => {
            navigate(info.key);
          }}
          selectedKeys={[currentKey]}
          items={items}
        />
      </Layout.Sider>
      <Layout.Content style={{ flex: 1, overflowY: "auto" }}>
        {children}
      </Layout.Content>
    </Layout>
  );
};

export default PageTemplate;

const ProfileContainer = styled.div`
  padding: 24px 12px;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;
