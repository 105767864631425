import { ApolloProvider, gql } from "@apollo/client";
import { ConfigProvider, theme } from "antd";
import kr from "antd/lib/locale/ko_KR";
import React from "react";

import initClient from "./apollo";
import { loggedInVar, generateCache } from "./cache";
import { useRefreshAccessTokenMutation } from "./client";

type GlobalProviderProps = {
  children: React.ReactNode;
};

gql`
  mutation refreshAccessToken {
    refreshAccessToken {
      accessToken
      expiresAt
    }
  }
`;

const ThemeContext = React.createContext({
  dark: false,
  setDark: (dark: boolean) => {
    alert("setDark is not defined: dark status: " + dark);
  },
});

export const useDarkMode = () => React.useContext(ThemeContext);

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const apolloClient = initClient(generateCache());
  const [dark, setDark] = React.useState(
    localStorage.getItem("dark") === "true"
  );

  const updateDarkMode = (dark: boolean) => {
    localStorage.setItem("dark", dark.toString());
    setDark(dark);
  };

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeContext.Provider value={{ dark, setDark: updateDarkMode }}>
        <ConfigProvider
          theme={{
            algorithm: dark ? theme.darkAlgorithm : theme.defaultAlgorithm,
          }}
          locale={kr}
        >
          {children}
        </ConfigProvider>
      </ThemeContext.Provider>
      <TokenRefreshProvider />
    </ApolloProvider>
  );
};

const TokenRefreshProvider = () => {
  const [refreshAccessTokenMutation] = useRefreshAccessTokenMutation();
  const [tokenExpiresAt, setTokenExpiresAt] = React.useState(0);

  const updateAccessToken = React.useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("token");
      if (!accessToken) {
        return;
      }
      const { data } = await refreshAccessTokenMutation();
      if (!data) {
        loggedInVar(false);
        return;
      }

      localStorage.setItem("token", data.refreshAccessToken.accessToken);
      setTokenExpiresAt(data.refreshAccessToken.expiresAt);
    } catch (err) {
      console.error(err);
    }
  }, [refreshAccessTokenMutation]);

  React.useEffect(() => {
    // 토큰 체커 등록
    const interval = setInterval(async () => {
      if (Date.now() > tokenExpiresAt) {
        await updateAccessToken();
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [updateAccessToken, tokenExpiresAt]);

  React.useEffect(() => {
    // 최초 1회 토큰 리프레시
    updateAccessToken();
    const tab = window.top;

    if (!tab) {
      return;
    }
    // 관리자콘솔이 포커스 될때 토큰 리프레시 ()
    tab.onfocus = async () => {
      await updateAccessToken();
    };
  }, [updateAccessToken]);

  return <></>;
};
