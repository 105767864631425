import { SearchOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Result,
  Row,
  Space,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from "antd";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useMe } from "src/hooks/useMe";
import {
  AuthenticationMethodEnum,
  RoleEnum,
  UsersQuery,
  useUsersLazyQuery,
} from "src/utils/client";

gql`
  query Users($filter: AccountsFilter!, $limit: Int!, $offset: Int!) {
    accounts(filter: $filter, limit: $limit, offset: $offset) {
      id
      authenticationMethods
      email
      phone
      profile {
        avatarImage {
          url
        }
        bio
        nickname
      }
    }
  }
`;

type UsersFormInputs = {
  id?: string;
  email?: string;
  phone?: string;
  nickname?: string;
};

const { Title, Paragraph, Text, Link } = Typography;

const Users = () => {
  const { me } = useMe();
  const navigate = useNavigate();
  const [usersQuery, { data, loading, error }] = useUsersLazyQuery();

  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm<UsersFormInputs>();

  const columns: TableColumnsType<UsersQuery["accounts"][number]> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Link
          copyable
          onClick={() => {
            navigate(`/users/${id}`);
          }}
        >
          {id}
        </Link>
      ),
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "휴대폰 번호",
      dataIndex: "phone",
      key: "phone",
      width: 130,
    },
    {
      title: "닉네임",
      dataIndex: ["profile", "nickname"],
      key: "nickname",
      width: 180,
    },
    {
      title: "프로필",
      dataIndex: ["profile", "avatarImage", "url"],
      key: "avatarImage",
      width: 70,
      render: (url, { profile }) => (
        <Avatar src={url}>{profile.nickname.charAt(0)}</Avatar>
      ),
    },
    {
      title: "인증 방법",
      dataIndex: "authenticationMethods",
      key: "authenticationMethods",
      width: 150,
      render: (methods) => (
        <Space>
          {methods.includes(AuthenticationMethodEnum.Local) && (
            <Tag>이메일</Tag>
          )}
          {methods.includes(AuthenticationMethodEnum.Kakao) && (
            <Tag>카카오</Tag>
          )}
          {methods.includes(AuthenticationMethodEnum.Google) && <Tag>구글</Tag>}
          {methods.includes(AuthenticationMethodEnum.Apple) && <Tag>애플</Tag>}
        </Space>
      ),
    },
    {
      title: "AccountType",
      dataIndex: "__typename",
      key: "__typename",
      width: 145,
    },
  ];

  React.useEffect(() => {
    if (
      searchParams.get("email") ||
      searchParams.get("phone") ||
      searchParams.get("nickname")
    ) {
      usersQuery({
        variables: {
          filter: {
            email: searchParams.get("email") || undefined,
            phone: searchParams.get("phone") || undefined,
            nickname: searchParams.get("nickname") || undefined,
          },
          limit: 20,
          offset: 0,
        },
      });
      form.setFieldsValue({
        email: searchParams.get("email") || undefined,
        phone: searchParams.get("phone") || undefined,
        nickname: searchParams.get("nickname") || undefined,
      });
    }
  }, [form, searchParams, usersQuery]);

  const onFinish = async ({ id, email, phone, nickname }: UsersFormInputs) => {
    if (id) {
      navigate(`/users/${id}`);
    } else {
      setSearchParams({
        ...(email && { email }),
        ...(phone && { phone }),
        ...(nickname && { nickname }),
      });
    }
  };

  if (me?.role !== RoleEnum.Admin) {
    return null;
  }

  return (
    <Layout className="content-container">
      <Title level={2}>유저 검색</Title>
      <Paragraph>
        이메일, 휴대폰 번호, 닉네임을 이용하여 유저를 검색할 수 있습니다. ID를
        제외한 모든 검색 조건은 OR 입니다.
      </Paragraph>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item name={"id"}>
              <Input type="id" placeholder="ID" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name={"email"}>
              <Input type="email" placeholder="이메일" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={"phone"}>
              <Input type="phone" placeholder="휴대폰 번호" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"nickname"}>
              <Input type="nickname" placeholder="닉네임" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
              검색
            </Button>
          </Col>
        </Row>
      </Form>
      {error && (
        <Result
          status={"error"}
          title="조회 중 문제가 발생했습니다."
          subTitle={error.message}
        />
      )}
      <Table
        bordered
        loading={loading}
        columns={columns}
        dataSource={data?.accounts}
        pagination={false}
        sticky
        size="small"
        rowKey="id"
      />
    </Layout>
  );
};

export default Users;
