import { PlusOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Button,
  Descriptions,
  Divider,
  Input,
  Modal,
  Space,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { RcFile } from "antd/lib/upload";
import dayjs from "dayjs";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Loading from "src/components/Loading";
import MediaFilesPreview from "src/components/MediaFilesPreview";
import { useFileUpload } from "src/hooks/useFileUpload";
import {
  useComplaintQuery,
  useLeaveComplaintResponseMutation,
} from "src/utils/client";

gql`
  mutation leaveComplaintResponse($data: CreateComplaintResponseData!) {
    leaveComplaintResponse(data: $data) {
      ... on LeaveComplaintResponseSuccess {
        response {
          id
          author {
            id
            nickname
            avatar {
              url
            }
          }
          complaint {
            id
          }
          body
          createdAt
          files {
            url
          }
        }
      }
    }
  }

  query Complaint($complaintId: ID!) {
    complaint(complaintId: $complaintId) {
      id
      author {
        id
        nickname
        avatar {
          url
        }
      }

      body
      counsel {
        id
      }
      files {
        url
      }
      order {
        id
        createdAt
        payment {
          id
          completedAt
          amount
        }
      }
      response {
        id
        author {
          id
          nickname
          avatar {
            url
          }
        }
        files {
          url
        }
        body
        viewed
        createdAt
      }
      createdAt
    }
  }
`;

gql`
  query userContentFileUpload($file: FileUploadInput!) {
    userContentFileUpload(file: $file) {
      fileUrl
      uploadUrl
    }
  }
`;

const { Title } = Typography;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Complaint = () => {
  const [writeComplaint, setWriteComplaint] = React.useState(false);
  const [responseBody, setResponseBody] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");

  const { id } = useParams();
  const { upload } = useFileUpload();
  const { data, loading, error } = useComplaintQuery({
    skip: !id,
    variables: { complaintId: id || "" },
  });
  const [leaveComplaintResponseMutation] = useLeaveComplaintResponseMutation({
    update: (cache, { data }) => {
      cache.writeFragment({
        id: `Complaint:${id}`,
        fragment: gql`
          fragment Complaint on Complaint {
            id
            response {
              id
            }
          }
        `,
        data: {
          id,
          response: data?.leaveComplaintResponse?.response,
        },
      });
    },
  });

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url?.lastIndexOf("/") + 1) || ""
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList }) =>
    setFileList(fileList);

  const handleUpload = ({ file, onSuccess, onError }: UploadRequestOption) => {
    if (file instanceof File) {
      upload(file)
        .then((res) => {
          onSuccess?.(res, new XMLHttpRequest());
        })
        .catch(onError);
    }
  };

  return (
    <Container>
      <Title level={2}>
        고객만족센터 문의: <code>{data?.complaint.id}</code>
      </Title>
      {loading && <Loading />}
      {error && (
        <div>
          에러 발생: <span>{error.message}</span>
        </div>
      )}
      {data && id && (
        <>
          <Divider />
          <Title level={3}>기본 정보</Title>
          <Descriptions bordered>
            <Descriptions.Item label="문의ID" span={1}>
              {data.complaint.id}
            </Descriptions.Item>
            <Descriptions.Item label="작성자" span={1}>
              <Tooltip title={data.complaint.author?.id}>
                <Button
                  type="link"
                  href={"/users/" + data.complaint.author?.id}
                >
                  {data.complaint.author?.nickname}
                </Button>
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="작성일" span={1}>
              {dayjs(data.complaint.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </Descriptions.Item>
            <Descriptions.Item label="내용" span={3}>
              {data.complaint.body}
            </Descriptions.Item>
            <Descriptions.Item label="상담" span={1}>
              {data.complaint.counsel && data.complaint.counsel.id}
            </Descriptions.Item>
            <Descriptions.Item label="주문" span={1}>
              {data.complaint.order && data.complaint.order.id}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Title level={3}>상담 정보</Title>
          {data.complaint.counsel ? (
            <Descriptions bordered>
              <Descriptions.Item label="상담ID" span={1}>
                {data.complaint.counsel.id}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <Title level={5} disabled>
              상담이 없습니다.
            </Title>
          )}
          <Divider />
          <Title level={3}>응답 정보</Title>
          {data.complaint.response ? (
            <Descriptions bordered>
              <Descriptions.Item label="응답ID" span={1}>
                {data.complaint.response.id}
              </Descriptions.Item>
              <Descriptions.Item label="응답자" span={1}>
                {data.complaint.response.author?.nickname}
              </Descriptions.Item>
              <Descriptions.Item label="응답일" span={1}>
                {dayjs(data.complaint.response.createdAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="내용"
                span={3}
                style={{ whiteSpace: "pre-line" }}
              >
                {data.complaint.response.body}
              </Descriptions.Item>
              <Descriptions.Item label="첨부파일" span={3}>
                <MediaFilesPreview files={data.complaint.files} />
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <ResponseWrapper>
              {writeComplaint ? (
                <>
                  <Title level={5}>응답을 작성해주세요.</Title>
                  <Input.TextArea
                    autoSize={{ minRows: 6 }}
                    onChange={(e) => setResponseBody(e.target.value)}
                    defaultValue={`안녕하세요 고객님, 닥터차 고객센터입니다.\n\n\n\n오늘 하루도 건강하시고 행복 가득하시길 바랍니다.\n닥터차 고객센터 드림.`}
                  />
                  <Upload
                    multiple
                    customRequest={handleUpload}
                    accept="image/*"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList.length >= 3 ? null : (
                      <div>
                        <PlusOutlined />
                        <div>사진 추가</div>
                      </div>
                    )}
                  </Upload>
                  <Modal
                    open={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt={previewTitle}
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                  <Space>
                    <Button
                      onClick={() => {
                        setWriteComplaint(false);
                        setResponseBody("");
                      }}
                    >
                      취소
                    </Button>
                    <Button
                      type="primary"
                      onClick={async () => {
                        await leaveComplaintResponseMutation({
                          variables: {
                            data: {
                              complaintId: id,
                              body: responseBody,
                              files: fileList.length
                                ? fileList.map((file) => ({
                                    url: file.response.url,
                                    type: file.response.type.toUpperCase(),
                                  }))
                                : undefined,
                            },
                          },
                        });
                        setResponseBody("");
                        setWriteComplaint(false);
                      }}
                    >
                      응답하기
                    </Button>
                  </Space>
                </>
              ) : (
                <>
                  <Title level={5} disabled>
                    응답 정보가 없습니다.
                  </Title>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      setWriteComplaint(true);
                    }}
                  >
                    응답 작성하기
                  </Button>
                </>
              )}
            </ResponseWrapper>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 4rem;
`;

const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export default Complaint;
