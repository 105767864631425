import {
  CompassOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Button,
  Card,
  Descriptions,
  Divider,
  Layout,
  Result,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link, useParams } from "react-router-dom";

import EstimateRequest from "./EstimateRequest";

import MediaFilesPreview from "src/components/MediaFilesPreview";
import { useEstimateInquiryQuery } from "src/utils/client";
import env from "src/utils/env";
import { insuranceTypeFormatter } from "src/utils/formatter";

gql`
  query EstimateInquiry($inquiryId: ID!) {
    estimateInquiry(inquiryId: $inquiryId) {
      id
      client {
        name
        phone
        account {
          id
          profile {
            avatarImage {
              url
            }
          }
        }
      }
      detail {
        body
        files {
          url
        }
        areas {
          radius
          name
          zone {
            province
            district
            neighborhood
          }
        }
        insurance
      }
      vehicle {
        modelName
        modelYear
        plateNumber
        vin
      }
      estimateRequests {
        id
        company {
          id
        }
        responsedAt
      }
      createdAt
    }
  }
`;

const { Title, Text } = Typography;

const EstimateInquiry = () => {
  const { inquiryId, companyId } = useParams();
  const { data, loading, error } = useEstimateInquiryQuery({
    skip: !inquiryId,
    variables: { inquiryId: inquiryId || "" },
  });

  if (loading) {
    return (
      <Spin>
        <Layout className="content-container"></Layout>
      </Spin>
    );
  } else if (error) {
    return (
      <Result
        status={"error"}
        title={"견적 문의를 불러오는데 실패했습니다."}
        subTitle={error.message}
      />
    );
  } else if (!data?.estimateInquiry) {
    return (
      <Result
        status={"404"}
        title={"견적 문의를 찾을 수 없습니다."}
        subTitle={"견적 문의가 존재하지 않거나 삭제되었습니다."}
      />
    );
  } else {
    const {
      estimateInquiry: {
        detail,
        client,
        vehicle,
        id,
        createdAt,
        estimateRequests,
      },
    } = data;
    return (
      <Layout className="content-container">
        <Title level={2}>견적 문의 상세</Title>
        <Card size="small">
          <Descriptions
            size="small"
            bordered
            column={1}
            labelStyle={{ width: 160 }}
          >
            <Descriptions.Item label="견적문의ID">
              <Text copyable>{id}</Text>
              <Divider type="vertical" />
              <Button
                href={env.WEB_URL + "/estimate-inquiries/" + inquiryId}
                icon={<CompassOutlined />}
              >
                웹에서 보기
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label="문의 지역">
              {detail?.areas.map((area, i) => (
                <Tag icon={<EnvironmentOutlined />} key={i}>
                  {area.zone?.province} {area.zone?.district}{" "}
                  {area.zone?.neighborhood} ({area.radius / 1000}km 반경)
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="문의 일자">
              {dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </Descriptions.Item>
            <Descriptions.Item label="고객 정보">
              <Space split={<Divider type="vertical" />}>
                {client?.account ? (
                  <Tooltip title="닥터차 유저입니다">
                    <Space>
                      <Avatar
                        src={client.account?.profile.avatarImage?.url}
                        icon={<UserOutlined />}
                      />
                      <Link to={`/users/${client.account.id}`}>
                        {client.name}
                      </Link>
                    </Space>
                  </Tooltip>
                ) : (
                  <Text>{client?.name}</Text>
                )}
                <Text copyable>{client.phone}</Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Space>
                  차량정보
                  <Tooltip title="닥터차앱으로 요청한 견적일 경우, vehicleId가 포함되어있을 수 있습니다. 만약 vehicleId가 필요하시다면, 슬랙에 제안해주세요">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
            >
              <Space split={<Divider type="vertical" />}>
                <Text>{vehicle.modelName}</Text>
                <Text>{vehicle.modelYear}년식</Text>
                {vehicle.plateNumber ? (
                  <Text copyable>{vehicle.plateNumber}</Text>
                ) : (
                  <Text type="secondary">차량번호 없음</Text>
                )}
                {vehicle.vin ? (
                  <Text copyable>{vehicle.vin}</Text>
                ) : (
                  <Text type="secondary">차대번호 없음</Text>
                )}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="보험">
              <Tag color={insuranceTypeFormatter(detail.insurance).color}>
                {insuranceTypeFormatter(detail.insurance).text}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="문의 내용">
              {detail.body}
            </Descriptions.Item>
            <Descriptions.Item label="첨부자료">
              <MediaFilesPreview files={detail.files} height={400} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Divider />
        <Space direction="vertical" style={{ display: "flex" }}>
          <Card size={"small"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Space>
                <Text strong>요청된 견적</Text>
                <Text type="secondary">{estimateRequests?.length}개</Text>
              </Space>
              {companyId && (
                <Link to={`/estimate-inquiries/${inquiryId}`}>
                  <Tooltip title="클릭하여 걸려있는 필터를 해제">
                    <Button type="primary" danger>
                      1개 업체 선택됨
                    </Button>
                  </Tooltip>
                </Link>
              )}
            </div>
          </Card>
          {estimateRequests?.length ? (
            <Space direction="vertical" style={{ display: "flex" }}>
              {estimateRequests
                ?.filter(
                  (request) => !companyId || request.company?.id === companyId
                )
                ?.map((request, index) => (
                  <EstimateRequest key={index} requestId={request.id} />
                ))}
            </Space>
          ) : (
            <Result
              status={"404"}
              title={"요청된 견적이 없습니다."}
              subTitle={"요청된 견적이 없습니다."}
            />
          )}
        </Space>
      </Layout>
    );
  }
};

export default EstimateInquiry;
