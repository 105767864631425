import "antd/dist/reset.css";
import { Button, Result } from "antd";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import Router from "./Router";
import { RoleEnum } from "./utils/client";

import Loading from "src/components/Loading";
import { useMe } from "src/hooks/useMe";
import Auth from "src/pages/Auth";

const App: React.FC = () => {
  const { me, loading, logout } = useMe();

  if (loading) {
    return <Loading />;
  }

  if (me) {
    if (me.role === RoleEnum.Admin) {
      return (
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      );
    } else {
      return (
        <Result
          title="권한이 없습니다."
          status={"error"}
          subTitle="고객만족센터 문의는 관리자만 접근할 수 있습니다."
          extra={[
            <Button key="logout" onClick={logout}>
              로그아웃
            </Button>,
          ]}
        />
      );
    }
  }

  return <Auth />;
};

export default App;
