import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Button,
  Card,
  Divider,
  Layout,
  Result,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

import MediaFilesPreview from "src/components/MediaFilesPreview";
import { useNoticeConnectionQuery } from "src/utils/client";

const { Title, Paragraph, Text, Link } = Typography;

gql`
  query NoticeConnection(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    noticeConnection(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        node {
          id
          title
          body
          files {
            url
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

const Notices = () => {
  const { data, loading, error, refetch } = useNoticeConnectionQuery({
    variables: {
      first: 20,
    },
  });

  const navigate = useNavigate();

  return (
    <Layout className="content-container">
      <Title level={2}>공지사항 관리</Title>
      <Paragraph type="secondary">
        공지사항은 모든 유저들에게 노출되는 정보이므로 신중하게 작성해주세요.
        정렬 순서는 최신순이며, 공지사항 삭제는 불가능합니다.
      </Paragraph>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Space>
          <Button
            type="primary"
            onClick={() => navigate("/notices/create")}
            icon={<PlusOutlined />}
          >
            공지사항 생성
          </Button>
          <Tooltip title="목록 새로고침" placement="right">
            <Button
              icon={<ReloadOutlined />}
              onClick={() => {
                refetch();
              }}
            />
          </Tooltip>
        </Space>
        {error ? (
          <Result
            status="error"
            title="공지사항을 불러오는 중 오류가 발생했습니다."
            subTitle={error.message}
          />
        ) : (
          data?.noticeConnection.edges.map(({ node }, index) => (
            <Card
              key={node.id}
              title={node.title}
              onClick={() => navigate(`/notices/${node.id}`)}
              style={{ cursor: "pointer" }}
              extra={
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/notices/${node.id}/edit`);
                  }}
                >
                  수정
                </Link>
              }
            >
              <Paragraph>{node.body}</Paragraph>
              <MediaFilesPreview files={node.files} height={200} />
              <Divider />
              <Text type="secondary">
                No. {index + 1} | 생성일{" "}
                {dayjs(node.createdAt).format("YYYY.MM.DD A hh:mm")}
              </Text>
            </Card>
          ))
        )}
        <Spin spinning={loading} />
        <Paragraph type="danger">
          현재는 최신 20개의 공지사항만 불러옵니다. 확인이 되지 않는 공지사항이
          있을 경우 관리자에게 문의해주세요.
        </Paragraph>
      </Space>
    </Layout>
  );
};

export default Notices;
