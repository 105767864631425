import { Result } from "antd";
import React from "react";
import { Routes, Route } from "react-router-dom";

import EstimateInquiries from "./pages/EstimateInquiries";
import EstimateInquiry from "./pages/EstimateInquiry";
import EstimateRequests from "./pages/EstimateRequests";
import Estimates from "./pages/Estimates";
import Home from "./pages/Home";
import CreateNotice from "./pages/Notice/CreateNotice";
import Notice from "./pages/Notice/Notice";
import Notices from "./pages/Notice/Notices";
import UpdateNotice from "./pages/Notice/UpdateNotice";
import Reviews from "./pages/Reviews";

import PageTemplate from "src/components/PageTemplate";
import Complaint from "src/pages/Complaint";
import Complaints from "src/pages/Complaints";
import User from "src/pages/User";
import Users from "src/pages/Users";
import Vehicle from "src/pages/Vehicle";

const Router = () => {
  return (
    <PageTemplate>
      <Routes>
        <Route path="/complaints" element={<Complaints />} />
        <Route path="/complaints/:id" element={<Complaint />} />
        <Route path="/vehicles" element={<Vehicle />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<User />} />
        <Route path="/notices" element={<Notices />} />
        <Route path="/notices/:id" element={<Notice />} />
        <Route path="/notices/create" element={<CreateNotice />} />
        <Route path="/notices/:id/edit" element={<UpdateNotice />} />
        <Route path="/estimate-requests" element={<EstimateRequests />} />
        <Route path="/estimate-inquiries" element={<EstimateInquiries />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route
          path="/estimate-inquiries/:inquiryId"
          element={<EstimateInquiry />}
        />
        <Route
          path="/estimate-inquiries/:inquiryId/:companyId" // :companyId 가 있을 경우 견적요청으로서 활용
          element={<EstimateInquiry />}
        />
        <Route path="/estimates" element={<Estimates />} />
        <Route path="/" element={<Home />} />
        <Route
          path="*"
          element={
            <Result
              status={"error"}
              title="잘못된 URL입니다"
              subTitle="올바른 URL을 입력하시거나, 왼쪽 메뉴에서 원하는 페이지를 찾아주세요."
            />
          }
        />
      </Routes>
    </PageTemplate>
  );
};

export default Router;
