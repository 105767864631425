import { FormOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Alert,
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

import Estimate from "./Estimate";

import EstimateRequestMemo from "src/components/EstimateRequestMemo";
import {
  useEstimateRequestQuery,
  useResponseEstimateRequestMutation,
} from "src/utils/client";

const { Text } = Typography;

gql`
  query EstimateRequest($requestId: ID!) {
    estimateRequest(requestId: $requestId) {
      id
      company {
        id
        name
        address {
          road
        }
        contact
      }
      estimate {
        id
      }
      memo
      responsedAt
    }
  }

  mutation EditEstimateRequestMemo($requestId: ID!, $memo: String!) {
    editEstimateRequestMemo(requestId: $requestId, memo: $memo) {
      ... on EditEstimateRequestMemoFail {
        error {
          message
        }
      }
      ... on EditEstimateRequestMemoSuccess {
        request {
          id
          memo
        }
      }
    }
  }

  mutation responseEstimateRequest(
    $estimate: MakeEstimateData
    $requestId: ID!
  ) {
    responseEstimateRequest(estimate: $estimate, requestId: $requestId) {
      ... on ResponseEstimateRequestSuccess {
        request {
          id
          hasResponsed
          responsedAt
          estimate {
            id
            createdAt
            price
            estimatedRepairDays
            hasPublished
            description
            files {
              ... on Image {
                url
              }

              ... on Video {
                url
              }
            }
          }
        }
      }

      ... on ResponseEstimateRequestFail {
        error {
          message
        }
      }
    }
  }
`;

type EstimateRequestProps = {
  requestId: string;
};

type EstimateRequestResponseFields = {
  description: string;
  price: number;
  estimatedRepairDays: number;
  files: { url: string }[];
};

const EstimateRequest = ({ requestId }: EstimateRequestProps) => {
  const { data, loading } = useEstimateRequestQuery({
    variables: {
      requestId,
    },
  });

  const request = data?.estimateRequest;

  const [responseEstimateRequest] = useResponseEstimateRequestMutation();

  const [form] = Form.useForm<EstimateRequestResponseFields>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  /*
   * File upload는 아직 지원 안함
   */
  const onSendEstimate = async () => {
    const values = await form.validateFields();

    const estimate = {
      description: values.description,
      price: values.price,
      estimatedRepairDays: values.estimatedRepairDays,
      // files: values.files,
    };

    const { data } = await responseEstimateRequest({
      variables: {
        requestId,
        estimate,
      },
    });

    if (
      data?.responseEstimateRequest.__typename ===
      "ResponseEstimateRequestSuccess"
    ) {
      notification.success({
        message: "견적서를 전송했습니다.",
      });
      setIsModalOpen(false);
    }
  };

  return (
    <Card size="small" loading={loading}>
      <Modal
        title="견적답변 작성"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={onSendEstimate}
        centered={true}
      >
        <Alert
          message={
            <Text>
              <Text strong>{request?.company?.name}</Text> 명의로 견적서를
              전송합니다.
            </Text>
          }
          type="info"
          showIcon
        />
        <Typography.Paragraph type="secondary" style={{ marginTop: 12 }}>
          {
            "* 견적서는 한 번 작성하면 수정할 수 없습니다.\n* 현재 PC버전은 파일 업로드를 지원하지 않습니다."
          }
        </Typography.Paragraph>
        <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
          <Form.Item
            name="description"
            label="견적 내용"
            rules={[{ required: true }]}
          >
            <Input.TextArea
              showCount
              autoSize={{ minRows: 10, maxRows: 50 }}
              maxLength={1000}
            />
          </Form.Item>
          <Form.Item
            name="price"
            label="견적 금액"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: 200 }}
              addonAfter="원"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Form.Item>
          <Form.Item
            name="estimatedRepairDays"
            label="예상 수리일"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: 200 }} addonAfter="일" max={65535} />
          </Form.Item>
          {/* <Form.Item
            name="files"
            label="첨부자료"
            rules={[{ required: true }]}
          >
            <Upload
              name="files"
              action={env.REACT_APP_GRAPHQL_ENDPOINT}
              headers={{
                authorization: localStorage.getItem("token") || "",
              }}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item> */}
        </Form>
      </Modal>
      <Descriptions
        size="small"
        bordered
        column={1}
        labelStyle={{ width: 160 }}
      >
        <Descriptions.Item label="견적 요청 메모">
          <EstimateRequestMemo
            initialMemo={data?.estimateRequest?.memo || ""}
            requestId={requestId}
          />
        </Descriptions.Item>
        <Descriptions.Item label="업체정보">
          <Space split={<Divider type="vertical" />}>
            <Link to={`/estimate-requests?companyId=${request?.company?.id}`}>
              {request?.company?.name}
            </Link>
            <Text copyable>{request?.company?.contact}</Text>
            <Text>{request?.company?.address.road}</Text>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="응답일">
          {request?.responsedAt ? (
            <Text>
              {" "}
              {dayjs(request?.responsedAt).format("YYYY-MM-DD HH:mm:ss")}
            </Text>
          ) : (
            <Text type="danger">응답 안함</Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="견적서">
          {request?.responsedAt ? (
            request?.estimate?.id ? (
              <Estimate estimateId={request?.estimate?.id} />
            ) : (
              <Text type="warning">응답 없이 완료로 전환</Text>
            )
          ) : (
            <Space split={<Divider type="vertical" />}>
              <Text disabled>견적서 없음</Text>
              <Button
                icon={<FormOutlined />}
                onClick={() => setIsModalOpen(true)}
              >
                견적서 작성
              </Button>
            </Space>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default EstimateRequest;
