import { Image } from "antd";
import React from "react";

type MediaFilesPreviewProps = {
  files:
    | (
        | {
            __typename?: "Image" | undefined;
            url: string;
          }
        | {
            __typename?: "Video" | undefined;
            url: string;
          }
      )[]
    | undefined;
  height?: number;
};

const MediaFilesPreview = ({ files, height = 180 }: MediaFilesPreviewProps) => {
  if (!files) return null;
  return (
    <div
      style={{
        display: "flex",
        gap: 8,
        marginTop: 12,
        marginBottom: 8,
        overflow: "hidden",
      }}
    >
      {files.length ? (
        <Image.PreviewGroup>
          {files
            .filter((file) => file.__typename === "Image")
            .map((file, index) => {
              return (
                <Image
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  key={index}
                  src={file.url}
                  height={height}
                />
              );
            })}
        </Image.PreviewGroup>
      ) : null}
      <span>
        {files
          .filter((file) => file.__typename === "Video")
          .map((file, index) => {
            return (
              <span key={index}>
                <video height={height} controls>
                  <source src={file.url} />
                </video>
              </span>
            );
          })}
      </span>
    </div>
  );
};

export default MediaFilesPreview;
