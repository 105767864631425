import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { gql } from "@apollo/client";
import {
  Avatar,
  Card,
  Divider,
  Layout,
  Result,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
  Typography,
} from "antd";
import Segmented, { SegmentedValue } from "antd/es/segmented";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

import { EstimatesQuery, useEstimatesLazyQuery } from "src/utils/client";

gql`
  query Estimates($filter: EstimateFilter!, $limit: Int!, $offset: Int!) {
    estimateCount(filter: $filter)
    estimates(filter: $filter, limit: $limit, offset: $offset) {
      id
      price
      description
      estimatedRepairDays
      files {
        url
      }
      hasPublished
      publisher {
        id
        profile {
          avatarImage {
            url
          }
          nickname
        }
      }
      createdAt
      request {
        id
        company {
          id
          name
        }
        inquiry {
          id
        }
      }
    }
  }
`;

const { Title, Text } = Typography;

const PAGE_SIZE = 20;

const columns: TableColumnsType<EstimatesQuery["estimates"][number]> = [
  {
    title: "견적문의ID / 업체명",
    dataIndex: "id",
    width: 60,
    key: "id",
    render: (_, { request }) => (
      <Space direction="vertical">
        <Tooltip title={"클릭하여 문의내용 확인"}>
          <Link
            to={`/estimate-inquiries/${request?.inquiry?.id}/${request?.company?.id}`}
          >
            {request?.inquiry?.id}
          </Link>
        </Tooltip>
        <Text type={"secondary"}>{request?.company?.name}</Text>
      </Space>
    ),
  },
  {
    title: "발행",
    dataIndex: "hasPublished",
    key: "hasPublished",
    width: 70,
    render: (_, { hasPublished, publisher }) =>
      hasPublished ? (
        <Space split={<Divider type="vertical" />}>
          <Text type="success">
            <CheckCircleOutlined /> 발행됨
          </Text>
          {publisher === null ? (
            <Text type="secondary">자동 발행</Text>
          ) : (
            <Tooltip title="견적 발행자">
              <Space>
                <Avatar
                  size={"small"}
                  src={publisher?.profile.avatarImage?.url}
                  icon={<UserOutlined />}
                />
                <Text>{publisher?.profile?.nickname}</Text>
              </Space>
            </Tooltip>
          )}
        </Space>
      ) : (
        <Text type="danger">
          <ExclamationCircleOutlined /> 발행대기
        </Text>
      ),
  },
  {
    title: "가격",
    dataIndex: "price",
    key: "price",
    align: "right",
    width: 25,
    render: (_, { price }) => <Text>{price?.toLocaleString()}원</Text>,
  },
  {
    title: "예상 수리기간",
    dataIndex: "estimatedRepairDays",
    key: "estimatedRepairDays",
    align: "right",
    width: 25,
    render: (_, { estimatedRepairDays }) => (
      <Text>{estimatedRepairDays}일</Text>
    ),
  },
  {
    title: "설명",
    dataIndex: "description",
    key: "description",
    width: 90,
  },
  {
    title: "견적서 생성일",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 40,
    render: (_, { createdAt }) => (
      <Text type="secondary">
        {dayjs(createdAt).format("YYYY-MM-DD HH:mm")}
      </Text>
    ),
  },
];

const Estimates = () => {
  const [estimatesQuery, { data, loading, error }] = useEstimatesLazyQuery();

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const [published, setPublished] = React.useState("no" as SegmentedValue);

  React.useEffect(() => {
    estimatesQuery({
      variables: {
        filter: {
          hasPublished: published === "all" ? undefined : published === "yes",
        },
        offset: (page - 1) * pageSize,
        limit: pageSize,
      },
    });
  }, [page, pageSize, estimatesQuery, published]);

  return (
    <Layout className="content-container">
      <Title level={2}>견적서 목록</Title>
      <Card size="small">
        <Space>
          <Text>상태: </Text>
          <Segmented
            value={published}
            onChange={(value) => {
              setPublished(value);
            }}
            options={[
              { value: "no", label: "발행 대기 중" },
              { value: "yes", label: "발행 완료" },
              { value: "all", label: "전체" },
            ]}
            onResize={undefined}
            onResizeCapture={undefined}
          />
          <Divider type="vertical" />
          <Text type="secondary">{data?.estimateCount}개의 견적서 조회됨</Text>
        </Space>
      </Card>
      {error && (
        <Result
          status={"error"}
          title={"견적서 목록을 불러오는데 실패했습니다."}
          subTitle={error.message}
        />
      )}
      <Table
        dataSource={data?.estimates}
        columns={columns}
        loading={loading}
        bordered
        sticky
        pagination={{
          position: ["topLeft", "bottomLeft"],
          current: page,
          pageSize,
          total: data?.estimateCount,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      />
    </Layout>
  );
};

export default Estimates;
